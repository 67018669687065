import { AxiosResponse } from 'axios';
import apiClient from './interceptors/RequestInterceptor';

export const getRequest = async <T>(path: string, params = {}): Promise<T> => {
  return apiClient.get(path, params).then((response: AxiosResponse<T>) => {
    return response.data;
  });
};

export const postRequest = async <T>(path: string, body: T): Promise<T> => {
  return apiClient.post(path, body).then((response: AxiosResponse<any>) => {
    return response.data;
  });
};

export const deleteRequest = async <T>(path: string): Promise<T> => {
  return apiClient.delete(path).then((response: AxiosResponse<T>) => {
    return response.data;
  });
};
