import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { formatCurrency, formatMetroCubico, formatPeso } from '../../../utils/formatter';

const Totals = () => {
  const notas = useSelector(
    (state: RootState) => state.buildCarregamento.notas
  );
  var pesoCubado = 0;
  var pesoReal = 0;
  var valorFreteBruno = 0;
  var qtdnotas = 0;
  var qtdEntregas = 0;
  var m3 = 0;

  var destinatarioMap = new Map();
  notas.forEach((nota) => {
    pesoCubado += nota.pesoCubado;
    pesoReal += nota.pesoReal;
    m3 += nota.pesoCubado / 300;
    valorFreteBruno += nota.valorFrete;
    qtdnotas++;
    destinatarioMap.set(nota.cnpjDestinatario, nota.cnpjDestinatario);
  });
  qtdEntregas = qtdEntregas + Array.from(destinatarioMap.keys()).length;



  return (
    <div className='flex items-center text-sm justify-center gap-10'>
      <div className='flex gap-3'>
        <div className='text-gray-400'>Qtd. notas:</div>
        <div className='text-gray-100'>{qtdnotas}</div>
      </div>
      <div className='flex gap-3'>
        <div className='text-gray-400'>Qtd. Entregas:</div>
        <div className='text-gray-100'>{qtdEntregas}</div>
      </div>
      <div className='flex gap-3'>
        <div className='text-gray-400'>Peso Real:</div>
        <div className='text-gray-100'>{formatPeso(pesoReal, 0)}</div>
      </div>
      <div className='flex gap-3'>
        <div className='text-gray-400'>Peso Cubado:</div>
        <div className='text-gray-100'>{formatPeso(pesoCubado, 0)}</div>
      </div>
      <div className='flex gap-3'>
        <div className='text-gray-400'>M3:</div>
        <div className='text-gray-100'>{formatMetroCubico(m3, 0)}</div>
      </div>      
      <div className='flex gap-3'>
        <div className='text-gray-400'>Valor Bruto:</div>
        <div className='text-gray-100'>
          {formatCurrency(valorFreteBruno, 2)}
        </div>
      </div>
    </div>
  );
};
export default Totals;
