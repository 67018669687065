import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaginaCarregamento } from '../../../services/PaginaService';

export const fetchCarregamentoData = createAsyncThunk(
  'carregamento/fetch',
  async () => {
    var notasCarregamento = await getPaginaCarregamento();
    return notasCarregamento;
  }
);
