import { createSlice } from '@reduxjs/toolkit';
import { Cliente } from '../../../../../data/types/Cliente';

export interface ClienteState {
  updateForm: {
    isOpen: boolean;
    cliente: Cliente;
    isSaving: boolean;
    tab: number;
  };
}

const initialState = {
  updateForm: {
    isOpen: false,
    cliente: {} as Cliente,
    isSaving: false,
    tab: 0,
  },
} as ClienteState;

const clienteSlice = createSlice({
  name: 'cliente',
  initialState,
  reducers: {
    openFormUpdateCliente: (state, action) => {
      state.updateForm.isOpen = true;
      state.updateForm.cliente = action.payload;
    },
    closeFormUpdateCliente: (state) => {
      state.updateForm.isOpen = false;
      state.updateForm.cliente = initialState.updateForm.cliente;
    },
    saving: (state) => {
      state.updateForm.isSaving = true;
    },
    saved: (state) => {
      state.updateForm.isSaving = false;
    },
    setTab: (state, action) => {
      state.updateForm.tab = action.payload;
    },
  },
});
export default clienteSlice.reducer;
