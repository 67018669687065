import { Parametro } from '../data/types/Parametro';
import { getRequest, postRequest } from './service';

export const getParametros = async (): Promise<Parametro[]> => {
  return await getRequest<Parametro[]>('/parametro');
};

export const createParametros = async (
  parametro: Parametro
): Promise<Parametro[]> => {
  return await postRequest<any>(`/parametro/${parametro.chave}`, {
    valores: parametro.valores,
  });
};
