import Divider from '@mui/material/Divider';
import { BsExclamationLg } from 'react-icons/bs';
import { getNotasVencidas } from '../../../utils/nota';
import { Nota } from '../../../data/types/Nota';

type TableAtrasadasProps = {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode[];
};

const TableAtrasadas = (prop: TableAtrasadasProps) => {
  const title = prop.title;
  const icon = prop.icon;
  const childrens = prop.children;
  return (
    <div className='shadow-sm col-span-12 md:col-span-6 bg-white p-5'>
      <div className='flex mb-5'>
        <div className=''>{title}</div>
        <div className='ml-auto'>
          <div className='rounded-full bg-primary-200 text-2xl p-2'>{icon}</div>
        </div>
      </div>

      {childrens.map((children, index) => (
        <div key={index}>
          {children}
          {index + 1 < childrens.length && <Divider light />}
        </div>
      ))}
    </div>
  );
};
export default TableAtrasadas;
