import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { useDispatch } from 'react-redux';
import { changeUnidadeFilter } from './state/atrasadasAction';
import { columns } from './columns';
import React from 'react';
import { fetchAtrasadasData } from './state/atrasadasFetch';
import Loading from '../../components/Loading';
import GridUnidadeFiltro from '../../components/GridUnidadeFiltro';
import Page from '../../components/Page';

const getUnidade = (): string[] => {
  const unidades = useSelector(
    (state: RootState) => state.atrasadas.data.notas
  ).map((nota) => (nota.rota != null ? nota.rota.unidade.nome : nota.unidade));
  return Array.from(new Set(unidades));
};

const Atrasadas = () => {
  React.useEffect(() => {
    dispatch(fetchAtrasadasData());
  }, []);

  const dispatch: AppDispatch = useDispatch();
  const notas = useSelector((state: RootState) => state.atrasadas.data.notas);
  const unidades = getUnidade();

  const unidadesFiltro = useSelector(
    (state: RootState) => state.atrasadas.unidadesFilter
  );

  if (notas.length === 0) {
    return <Loading />;
  }
  return (
    <Page title={`Notas Atrasadas (${notas.length})`}>
      <GridUnidadeFiltro
        notas={notas}
        unidades={unidades}
        unidadesFiltro={unidadesFiltro}
        changeUnidadeFilter={(unidades: string[]) =>
          dispatch(changeUnidadeFilter(unidades))
        }
        columns={columns}
        gridName='Notas Atrasadas'
      />
    </Page>
  );
};
export default Atrasadas;
