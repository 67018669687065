import { createSlice, current } from '@reduxjs/toolkit';
import { fetchPendenciaData } from './pendenciaFetch';
import { Nota } from '../../../data/types/Nota';

export interface PendenciaState {
  unidadesFilter: string[];
  isLoading: boolean;
  data: {
    notas: Nota[];
    unidades: string[];
  };
}
const initialState = {
  unidadesFilter: [],
  isLoading: false,
  data: {
    notas: [],
    unidades: [],
  },
} as PendenciaState;

const pendenciaSlice = createSlice({
  name: 'pendencia',
  initialState,
  reducers: {
    changeUnidadeFilter: (state, action) => {
      state.unidadesFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPendenciaData.pending.type, (state) => {
      if (state.data.notas.length === 0) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchPendenciaData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.notas = action.payload;
    });
  },
});
export default pendenciaSlice.reducer;
