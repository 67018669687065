import StatusNota from '../../components/StatusNota';
import { formatDataHora, formatDate } from '../../utils/formatter';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Nota } from '../../data/types/Nota';
import NumeroNotaFiscal from '../../components/NumeroNotaFiscal';
import { Button, TextField } from '@mui/material';
import { useRef } from 'react';

export const columns = (handleCadastrarAcompanhamento: Function) => [
  {
    field: 'numeroNotaFiscal',
    headerName: 'NF',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<Nota>) => {
      return <NumeroNotaFiscal nota={row} />;
    },
  },
  {
    field: 'dataAutorizacao',
    headerName: 'Emissão',
    flex: 1,
    renderCell: (params: any) => {
      return <div>{formatDate(params.row.dataAutorizacao)}</div>;
    },
  },
  { field: 'remetente', headerName: 'Remetente', flex: 2 },
  {
    field: 'destinatario',
    headerName: 'Destinatário',
    flex: 2,
    renderCell: (params: any) => {
      return <div>{params.row.destinatario}</div>;
    },
  },
  { field: 'cidade', headerName: 'Cidade', flex: 1 },
  {
    field: 'unidadeDestino',
    headerName: 'Unidade',
    flex: 0.5,
    renderCell: ({ row }: GridRenderCellParams) => (
      <span>{row.unidadeDestino}</span>
    ),
  },
  {
    field: 'dataPrevisaoEntrega',
    headerName: 'Data Limite',
    flex: 1,
    renderCell: (params: any) => {
      return <StatusNota nota={params.row} />;
    },
  },
  {
    field: 'ultimaDescricao',
    headerName: 'Acompanhamento',
    flex: 4,
    renderCell: ({ row, api, id, field }: GridRenderCellParams<Nota>) => {
      const inputRef = useRef<HTMLInputElement>(null);

      return (
        <div className={`w-full`}>
          <div className={`py-5`}>
            {row.acompanhamento && row.acompanhamento.descricao ? (
              <div>
                <span className={`font-bold`}>
                  {row.acompanhamento.email}
                  {` `}
                  {formatDataHora(row.acompanhamento.data)}
                </span>
                {' - '}
                {row.acompanhamento.descricao}
              </div>
            ) : null}
          </div>
          <TextField
            inputRef={inputRef}
            variant='outlined'
            multiline
            rows={3}
            label='Status da nota'
            fullWidth
            inputProps={{ maxLength: 100 }}
            helperText={`Tamanho màximo de 100 caracteres`}
            onChange={(e) => {
              api.setEditCellValue({
                id: id,
                field: field,
                value: e.target.value,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === ' ') {
                e.stopPropagation(); // Previne o espaço de pular a linha
              }
            }}
          />
          <div className={`py-5 w-full flex justify-end`}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                if (inputRef.current) {
                  const descricao = inputRef.current.value;
                  handleCadastrarAcompanhamento(row.idNota, descricao);
                  inputRef.current.value = ''; // Limpa o valor do input
                }
              }}
            >
              Cadastrar
            </Button>
          </div>
        </div>
      );
    },
  },
];
