import { createSlice, current } from '@reduxjs/toolkit';
import { Nota } from '../../data/types/Nota';
import { fetchDashboardData } from './dashboardAction';

export interface DashboardState {
  unidadesFilter: string[];
  historicoDia: Nota[];
  historicoMesAtual: {
    dia: number;
    mes: string;
    frete?: number;
     
  }[];
  historicoMesAnterior: Nota[];
  qtdCteMes: number;
  faturamentoMes: number;
  faturamentoMesAnterior: number;
  performance: number;
  freteNaoEntregue: { tipo: string; frete: number; mediaDias: number }[];
  performanceUnidade: {
    unidade: string;
    qtdNfEntregue: number;
    qtdNoPrazo: number;
  }[];
  fretePorRota: {
    rota: string;
    frete: number;
    percentualFaturamento: number;
  }[];

  fretePorTipo: { tipo: string; frete: number;}[];

  notasMesAtual: Nota[];
  status: string;
  maioresClientes: {
    id: number;
    nome: string;
    freteTotal?: number;
    performance: number;
    percentual: number;
  }[];

  maioresDestinatarios: {
    id: number;
    nome: string;
    freteTotal?: number;
    percentual: number;
  }[];  
}
const initialState = {
  unidadesFilter: [],
  historicoDia: [],
  historicoMesAtual: [],
  historicoMesAnterior: [],
  qtdCteMes: 0,
  faturamentoMes: 0,
  faturamentoMesAnterior: 0,
  performance: 0,
  freteNaoEntregue: [],
  performanceUnidade: [],
  fretePorRota: [],
  fretePorTipo: [],
  maioresDestinatarios: [],
  notasMesAtual: [],
  status: '',
  maioresClientes: [],
} as DashboardState;

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    changeUnidadeFilter: (state, action) => {
      state.unidadesFilter = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchDashboardData.pending.type, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchDashboardData.fulfilled, (state, action) => {
      state.status = 'success';
      state.historicoDia = action.payload.historicoDia;
     // state.historicoMesAtual = action.payload.historicoDia;
      state.historicoMesAnterior = action.payload.historicoMesAnterior;
      state.qtdCteMes = action.payload.qtdCteMes;
      state.faturamentoMes = action.payload.faturamentoMes;
    //  state.faturamentoMesAnterior = action.payload.faturamentoMesAnterior;
      //state.performance = action.payload.performance;
      state.freteNaoEntregue = action.payload.freteNaoEntregue;
      state.maioresDestinatarios = action.payload.maioresDestinatarios;
      //state.fretePorTipo = action.payload.fretePorTipo;
      state.notasMesAtual = action.payload.notasMesAtual;
      state.maioresClientes = action.payload.maioresClientes;
      state.fretePorRota = action.payload.fretePorRota;
      state.performanceUnidade = action.payload.performanceUnidade;
    });
  },
});
export default dashboardSlice.reducer;
