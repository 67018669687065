import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { useDispatch } from 'react-redux';
import { changeUnidadeFilter } from './state/pendenciaAction';
import FiltroOcorrencia from '../../components/FiltroOcorrencia';
import { columns } from './columns';
import { CustomizedDataGrid } from '../../components/CustomizedDataGrid';
import { Nota } from '../../data/types/Nota';
import React from 'react';
import { fetchPendenciaData } from './state/pendenciaFetch';
import Loading from '../../components/Loading';
import Page from '../../components/Page';
import GridUnidadeFiltro from '../../components/GridUnidadeFiltro';

const getNotas = (): Nota[] => {
  const notas = useSelector((state: RootState) => state.pendencia.data.notas);
  return notas;
};

const getUnidade = (notas: Nota[]): string[] => {
  const unidades = getNotas().map((nota) =>
    nota.rota != null ? nota.rota.unidade.nome : nota.unidade
  );
  return Array.from(new Set(unidades));
};

const Pendencia = () => {
  React.useEffect(() => {
    dispatch(fetchPendenciaData());
  }, []);

  const dispatch: AppDispatch = useDispatch();
  const notas = getNotas();

  const unidades = getUnidade(notas);
  const unidadesFiltro = useSelector(
    (state: RootState) => state.pendencia.unidadesFilter
  );

  if (notas.length === 0) {
    return <Loading />;
  }

  return (
    <Page title={`Notas com Pendencia (${notas.length})`}>
      <GridUnidadeFiltro
        notas={notas}
        unidades={unidades}
        unidadesFiltro={unidadesFiltro}
        changeUnidadeFilter={(unidades: string[]) =>
          dispatch(changeUnidadeFilter(unidades))
        }
        columns={columns}
        gridName='Notas com Pendencia'
      />
    </Page>
  );
};
export default Pendencia;
