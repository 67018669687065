import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { useEffect, useState } from 'react';

const Main = () => {
  const [sideBarWidth, setSideBarWidth] = useState('');

  useEffect(() => {
    const sideBarWidth = localStorage.getItem('SIDEBAR_WITDH')?.toString();
    if (sideBarWidth && (sideBarWidth === '80' || sideBarWidth === '250')) {
      setSideBarWidth(sideBarWidth);
    } else {
      const windowWidth = window.innerWidth;
      if (window.innerWidth < 1000) {
        setSideBarWidth('80');
        localStorage.setItem('SIDEBAR_WITDH', '80');
      } else {
        setSideBarWidth('250');
        localStorage.setItem('SIDEBAR_WITDH', '250');
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('SIDEBAR_WITDH', sideBarWidth);
  }, [sideBarWidth]);

  if (sideBarWidth === '') {
    return;
  }

  return (
    <div
      className={`grid ${
        sideBarWidth === '80' ? 'grid-cols-[0px_auto]' : 'grid-cols-[0px_auto]'
      } ${
        sideBarWidth === '80'
          ? 'lg:grid-cols-[80px_auto]'
          : 'lg:grid-cols-[250px_auto]'
      } h-screen transition-all duration-300 ease-in-out`}
    >
      <div>
        <Sidebar
          setSideBarWidth={setSideBarWidth}
          sideBarWidth={sideBarWidth}
        />
      </div>
      <div className='grid grid-rows-[60px_auto] overflow-hidden'>
        <div className='z-50'>
          <Header
            sideBarWidth={sideBarWidth}
            setSideBarWidth={setSideBarWidth}
          />
        </div>
        <div className='flex overflow-hidden'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default Main;
