import StatusNota from '../../components/StatusNota';
import { formatDate } from '../../utils/formatter';
import { AppDispatch } from '../../state/store';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import NumeroNotaFiscal from '../../components/NumeroNotaFiscal';
import { Nota } from '../../data/types/Nota';

export const columns = [
  {
    field: 'numeroNotaFiscal',
    headerName: 'NF',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<Nota>) => {
      return <NumeroNotaFiscal nota={row} />;
    },
  },
  {
    field: 'dataAutorizacao',
    headerName: 'Emissão',
    flex: 1,
    renderCell: (params: any) => {
      return <div>{formatDate(params.row.dataAutorizacao)}</div>;
    },
  },
  { field: 'remetente', headerName: 'Remetente', flex: 4 },
  { field: 'destinatario', headerName: 'Destinatário', flex: 4 },
  { field: 'cidade', headerName: 'Cidade', flex: 2 },
  {
    field: 'unidade',
    headerName: 'Unidade',
    flex: 0.7,

    renderCell: (params: any) => {
      return <div>{params.row.unidade}</div>;
    },
  },
  {
    field: 'dataPrevisaoEntrega',
    headerName: 'Data Limite',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<Nota>) => (
      <StatusNota nota={row} />
    ),
  },
] as GridColDef[];
