import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Nota } from '../../../data/types/Nota';
import { Acompanhamento } from '../../../data/types/Acompanhamento';
import { fetchNotaData } from './notaFetch';
import * as AcompanhamentoService from './../../../services/AcompanhamentoService';
import { openSnackbar } from '../../../state/application/applicationAction';

export const openNotaTab = createAction<Nota>('nota/openNotaTab');
export const closeNotaTab = createAction('nota/closeNotaTab');

export const createAcompanhamento = createAsyncThunk(
  'nota/createAcompanhamento',
  async (acompanhamento: Acompanhamento, { dispatch }) => {
    await AcompanhamentoService.createAcompanhamento(
      acompanhamento.idNota,
      acompanhamento.descricao
    );
    dispatch(fetchNotaData(acompanhamento.idNota)).then(() => {
      dispatch(
        openSnackbar({
          message: 'Acompanhamento salvo com sucesso',
          severity: 'success',
        })
      );
    });
  }
);

createAcompanhamento;
