import { createSlice, current } from '@reduxjs/toolkit';
import { fetchMonitoradosData } from './monitoradosFetch';
import { Nota } from '../../../data/types/Nota';

export interface MonitoradosState {
  unidadesFilter: string[];
  isLoading: boolean;
  data: {
    notas: Nota[];
    unidades: string[];
  };
}
const initialState = {
  unidadesFilter: [],
  isLoading: false,
  data: {
    notas: [],
    unidades: [],
  },
} as MonitoradosState;

const monitoradosSlice = createSlice({
  name: 'monitorados',
  initialState,
  reducers: {
    changeUnidadeFilter: (state, action) => {
      state.unidadesFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMonitoradosData.pending.type, (state) => {
      if (state.data.notas.length === 0) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchMonitoradosData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.notas = action.payload.sort((a: Nota, b: Nota) => {
        const dataA = a.dataAgendamento
          ? a.dataAgendamento
          : a.dataPrevisaoEntrega;
        const dataB = b.dataAgendamento
          ? b.dataAgendamento
          : b.dataPrevisaoEntrega;
        if (dataA === dataB) {
          return 1;
        }
        return dataA > dataB ? 1 : -1;
      });
    });
  },
});
export default monitoradosSlice.reducer;
