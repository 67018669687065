import { Button, MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';
import PainelEntregas from './components/PainelEntregas';
import FormCreateEntregas from './components/formCreateEntregas/FormCreateEntregas';
import { AppDispatch, RootState } from '../../state/store';
import { useDispatch } from 'react-redux';
import { fetchEntregasData } from './state/entregasFetch';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import { closePanel, openPanel } from './state/entregasAction';
import { CiCirclePlus } from 'react-icons/ci';

// TODO: se tiver a placa do carro e motorista, mostrar no card
const Entregas = () => {
  const dispatch: AppDispatch = useDispatch();

  const isOpenPanel = useSelector(
    (state: RootState) => state.entregas.isOpenPanel
  );

  React.useEffect(() => {
    dispatch(fetchEntregasData());
  }, []);

  const entregas = useSelector(
    (state: RootState) => state.entregas.data.entregas
  );

  return (
    <div className='p-5 w-full h-full overflow-hidden'>
      <span className='text-2xl flex gap-5'>
        <div>Entregas</div>
        <div className='ml-auto'>
          <Button variant='text' onClick={() => dispatch(openPanel())}>
            <div className='flex items-center gap-2'>
              <CiCirclePlus className='text-[30px]' />
              Nova Entrega
            </div>
          </Button>
        </div>
      </span>
      <div
        className={`grid ${
          isOpenPanel ? 'grid-cols-[40%_60%]' : 'grid-cols-[100%_0px]'
        } p-5 gap-5 h-full transition-all duration-900`}
      >
        <div className='overflow-auto'>
          <PainelEntregas entregas={entregas} />
        </div>
        {isOpenPanel && (
          <FormCreateEntregas cancelar={() => dispatch(closePanel())} />
        )}
      </div>
    </div>
  );
};

export default Entregas;
