import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import { Nota } from '../data/types/Nota';

import FiltroOcorrencia from './FiltroOcorrencia';
import Loading from './Loading';
import CustomPagination from './CustomPagination';
import { getCurrentDateTimeInBRTFormat } from '../utils/date';

type GridUnidadeFiltroProps = {
  notas: Nota[];
  unidades?: string[];
  unidadesFiltro?: string[];
  changeUnidadeFilter?: (unidades: string[]) => void;
  columns: GridColDef[];
  gridName: string;
  rowHeight?: boolean;
};

const GridUnidadeFiltro = ({
  notas,
  unidades,
  unidadesFiltro,
  changeUnidadeFilter,
  columns,
  gridName,
  rowHeight,
}: GridUnidadeFiltroProps) => {
  if (notas.length === 0) {
    return <Loading />;
  }

  const filteredNotas = notas.filter((nota) => {
    if (unidadesFiltro !== undefined && unidades !== undefined) {
      if (unidadesFiltro.length === 0) {
        return unidades.includes(nota.unidade);
      } else {
        return unidadesFiltro.includes(nota.unidade);
      }
    }
    return nota;
  });

  let className = 'w-full grid grid-rows-[100px_auto] h-full overflow-hidden';
  if (unidades === undefined) {
    className = 'w-full grid grid-rows-[auto] h-full overflow-hidden';
  }

  return (
    <div className={className}>
      {unidades !== undefined &&
        changeUnidadeFilter !== undefined &&
        unidadesFiltro !== undefined && (
          <div className='overflow-hidden'>
            <FiltroOcorrencia
              notas={notas}
              filterCallback={(unidades: string[]) =>
                changeUnidadeFilter(unidades)
              }
              unidades={unidades}
              unidadesFiltro={unidadesFiltro}
              columns={columns.map((column) => column.field)}
            />
          </div>
        )}

      <div className='bg-white h-full overflow-hidden'>
        <DataGrid
          getRowHeight={rowHeight ? () => 'auto' : () => 50}
          slots={{
            toolbar: () => (
              <div className='p-5 text-xl'>{gridName ? gridName : ''}</div>
            ),
            pagination: (props) =>
              CustomPagination({
                props,
                data: filteredNotas,
                nome: `${gridName}`,
                columns,
              }),
          }}
          disableRowSelectionOnClick
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          initialState={{
            sorting: {
              sortModel: [{ field: 'nome', sort: 'asc' }],
            },
          }}
          rows={filteredNotas}
          getRowId={(nota) => nota.idNota}
          columns={columns}
          onCellKeyDown={(_, events) => events.stopPropagation()}
        />
      </div>
    </div>
  );
};
export default GridUnidadeFiltro;
