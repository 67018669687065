import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { useDispatch } from 'react-redux';
import StatusNotas from './StatusNotas';
import Tooltip from '@mui/material/Tooltip';
import { BsTruck } from 'react-icons/bs';
import { withStyles, createStyles } from '@material-ui/styles';
import { Nota } from '../../../data/types/Nota';
import { getFrete, getPesoCubado, getPesoReal } from '../../../utils/nota';
import { selectAll, selectNota } from '../buildCarregamentoAction';
import NotaFiscal from '../../../components/NotaFiscal';
import { OcorrenciaType } from '../../../data/enums/OcorrenciaType';
import { formatDate } from '../../../utils/formatter';
import StatusNota from '../../../components/StatusNota';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { openNotaTab } from '../../nota/state/notaAction';
import { FaTruck } from 'react-icons/fa';

const StyledTableCell = withStyles(() =>
  createStyles({
    root: {
      whiteSpace: 'nowrap',
    },
    body: {
      fontSize: 15,
    },
  })
)(TableCell);

type TableCteProps = {
  notas: Nota[];
  cidade: string;
};

const TableCte = (props: TableCteProps) => {
  const dispatch = useDispatch();
  const cidade = props.cidade;
  const notas = props.notas;

  const cor1 = '#fffbe6';
  const cor2 = '#fff8d0';
  const cor3 = '#7BAEFF';
  const cor4 = '#FFBBBB';
  var cor = cor1;

  var notasCarregamento = useSelector(
    (state: RootState) => state.buildCarregamento.notas
  ).filter((nota) => nota.cidade === cidade) as Nota[];

  var ocorrenciaPendencia = useSelector((state: RootState) =>
    state.parameter.data.parametros
      .find((parametro) => parametro.chave === 'OCORRENCIA_PENDENCIA')
      ?.valores.map((valor) => parseInt(valor))
  ) as number[];

  const [open, setOpen] = React.useState(false);

  var cnpjDestinatario = '';
  return (
    <React.Fragment key={notasCarregamento.length}>
      <TableRow
        hover
        sx={{ cursor: 'pointer', fontSize: 10 }}
        onClick={() => setOpen(notasCarregamento.length > 0 ? true : !open)}
        className='test-sm'
      >
        <TableCell>
          <IconButton>
            {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </IconButton>
        </TableCell>

        <StyledTableCell>
          <h3>
            {cidade} ({notas.length})
          </h3>
        </StyledTableCell>
        <TableCell>
          <StatusNotas notas={notas} />
        </TableCell>
        <StyledTableCell>{getPesoReal(notas, 0)}</StyledTableCell>
        <StyledTableCell>{getPesoCubado(notas, 0)}</StyledTableCell>
        <StyledTableCell
          sx={{
            textAlign: 'right',
            color: 'green',
            alignContent: 'right',
          }}
        >
          {getFrete(notas, 2)}
        </StyledTableCell>
      </TableRow>
      <TableRow sx={{ padding: '0px', '& > *': { borderBottom: 'unset' } }}>
        <TableCell
          style={{
            padding: 0,
          }}
          colSpan={10}
        >
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Table size='small'>
              <TableHead>
                <TableRow
                  sx={{
                    cursor: 'pointer',
                    padding: '0px',
                    backgroundColor: '#eeeeee',
                  }}
                >
                  <TableCell sx={{ padding: '0', marginLeft: '20' }}>
                    <Checkbox
                      color='primary'
                      checked={
                        notasCarregamento.length ===
                        notas.filter(
                          (nota) =>
                            !ocorrenciaPendencia.includes(nota.codigoOcorrencia)
                        ).length
                      }
                      indeterminate={
                        notasCarregamento.length > 0 &&
                        notasCarregamento.length <
                          notas.filter(
                            (nota) =>
                              !ocorrenciaPendencia.includes(
                                nota.codigoOcorrencia
                              )
                          ).length
                      }
                      onChange={(event) =>
                        dispatch(
                          selectAll({
                            select: event.target.checked,
                            cidade: cidade,
                            notas: notas.filter(
                              (nota) =>
                                !ocorrenciaPendencia?.includes(
                                  nota.codigoOcorrencia
                                )
                            ),
                          })
                        )
                      }
                    />
                  </TableCell>
                  <TableCell></TableCell>
                  <StyledTableCell
                    sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                    width='9%'
                  >
                    <h5>EMISSÃO</h5>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                    width='10%'
                  >
                    <h5>DATA LIMITE</h5>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                    width='5%'
                  ></StyledTableCell>
                  <StyledTableCell
                    sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                    width='25%'
                  >
                    <h5>REMETENTE</h5>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                    width='25%'
                  >
                    <h5>DESTINATÄTIO</h5>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                    width='10%'
                  >
                    <h5>NF</h5>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                    width='16%'
                  >
                    <h5>PESO REAL/CUBADO</h5>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ padding: '0', marginLeft: '20' }}
                    width='5%'
                  >
                    <h5>FRETE</h5>
                  </StyledTableCell>
                  <StyledTableCell sx={{ padding: '10' }}></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notas.map((nota, index) => {
                  if (cnpjDestinatario !== nota.cnpjDestinatario) {
                    if (cor === cor1) {
                      cor = cor2;
                    } else {
                      cor = cor1;
                    }
                    cnpjDestinatario = nota.cnpjDestinatario;
                  }
                  if (nota.agendado) {
                    cor = cor3;
                  }
                  return (
                    <TableRow
                      key={index}
                      sx={{ cursor: 'pointer', backgroundColor: cor }}
                    >
                      <TableCell sx={{ padding: '0px' }}>
                        {ocorrenciaPendencia?.includes(
                          nota.codigoOcorrencia
                        ) ? (
                          <Tooltip title={nota.descricaoUltimaOcorrencia}>
                            <div className='m-3 flex items-center justify-center text-bold w-5 text-xs rounded-full bg-vencido text-white pl-1 pr-1'>
                              P
                            </div>
                          </Tooltip>
                        ) : (
                          <Checkbox
                            color='primary'
                            checked={notasCarregamento
                              .map((c) => c.ctrc)
                              .includes(nota.ctrc)}
                            onChange={(event) =>
                              dispatch(
                                selectNota({
                                  select: event.target.checked,
                                  nota: nota,
                                })
                              )
                            }
                          />
                        )}
                      </TableCell>
                      <StyledTableCell sx={{ padding: '0 10 10 10' }}>
                        {nota.carregamento && (
                          <Tooltip title={'Ficha de Carregamento'}>
                            <div style={{ fontSize: 20, textAlign: 'center' }}>
                              <BsTruck />
                            </div>
                          </Tooltip>
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                      >
                        {formatDate(nota.dataAutorizacao)}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          padding: '0',
                          marginLeft: '20',
                          fontSize: 12,
                        }}
                      >
                        <StatusNota nota={nota} />
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ padding: '0', marginLeft: '20', fontSize: 10 }}
                      >
                        {nota.ctrc.substr(2, 1).includes('D') && (
                          <div>
                            <Tooltip title={'Devolução'}>
                              <span className=' flex items-center justify-center text-bold w-5 text-xs rounded-full bg-vencido text-white pl-1 pr-1'>
                                D
                              </span>
                            </Tooltip>
                          </div>
                        )}
                        {nota.ctrc.substr(2, 1).includes('R') && (
                          <div>
                            <Tooltip title={'Coleta Reversa'}>
                              <span className=' flex items-center justify-center text-bold w-5 text-xs rounded-full bg-vencido text-white pl-1 pr-1'>
                                R
                              </span>
                            </Tooltip>
                          </div>
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                      >
                        {nota.remetente.substring(0, 20)}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                      >
                        {nota.destinatario.substring(0, 20)}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                      >
                        {
                          <div className='text-[#3B82F6] flex'>
                            <button onClick={() => dispatch(openNotaTab(nota))}>
                              <div className='flex gap-1 items-center'>
                                <FaMagnifyingGlass />
                                <NotaFiscal
                                  numeroNotaFiscal={nota.numeroNotaFiscal}
                                />
                              </div>
                            </button>
                          </div>
                        }
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ padding: '0', marginLeft: '20', fontSize: 12 }}
                      >
                        {nota.pesoReal.toFixed(0)} /{' '}
                        {nota.pesoCubado.toFixed(0)}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          textAlign: 'right',
                          padding: '0',
                          marginLeft: '20',
                          fontSize: 12,
                          color: 'green',
                        }}
                      >
                        R$
                        {nota.valorFrete.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </StyledTableCell>
                      <StyledTableCell sx={{ padding: '10' }}></StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
export default TableCte;
