import { createSlice } from '@reduxjs/toolkit';
import { Rota } from '../../../../../data/types/Rota';

export interface RotaState {
  form: {
    isOpen: boolean;
    component: string;
  };
  updateForm: {
    rota: Rota;
  };
  isSaving: boolean;
}

const initialState = {
  form: {
    isOpen: false,
    component: '',
  },
  updateForm: {
    rota: {} as Rota,
  },
  isSaving: false,
} as RotaState;

const rotaSlice = createSlice({
  name: 'rota',
  initialState,
  reducers: {
    openForm: (state, action) => {
      state.form.isOpen = true;
      state.form.component = action.payload;
    },
    openFormUpdate: (state, action) => {
      state.form.isOpen = true;
      state.form.component = action.payload.component;
      state.updateForm.rota = action.payload.rota;
    },
    closeForm: (state) => {
      if (!state.isSaving) {
        state.form.isOpen = false;
        state.form.component = '';
      }
    },
    saving: (state) => {
      state.isSaving = true;
    },

    saved: (state) => {
      state.isSaving = false;
    },
  },
});
export default rotaSlice.reducer;
