import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApplicationData } from './applicationFetch';
import { Cidade } from '../../data/types/Cidade';
import { Cliente } from '../../data/types/Cliente';
import { Rota } from '../../data/types/Rota';
import { Nota } from '../../data/types/Nota';
import { Ocorrencia } from '../../data/types/Ocorrencia';
import { Unidade } from '../../data/types/Unidade';

export interface ApplicationState {
  isMobile: boolean;
  data: {
    notas: Nota[];
    unidades: string[];
  };
  isParameterOpen: boolean;
  autoCloseParameter: boolean;
  snackbar: {
    isOpen: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
  };
}

const initialState = {
  isMobile: false,
  data: {
    notas: [],
    unidades: [],
  },
  isParameterOpen: false,
  autoCloseParameter: false,
  snackbar: {
    isOpen: false,
    message: '',
    severity: 'success',
  },
} as ApplicationState;

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    isMobile: (state) => {
      state.isMobile = true;
    },
    isWeb: (state) => {
      state.isMobile = false;
    },
    openParameter: (state, action) => {
      state.isParameterOpen = true;
      state.autoCloseParameter = action.payload ? true : false;
    },
    closeParameter: (state) => {
      state.isParameterOpen = false;
      state.autoCloseParameter = false;
    },
    openSnackbar: (state, action) => {
      state.snackbar = {
        isOpen: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    closeSnackbar: (state) => {
      state.snackbar = {
        isOpen: false,
        message: '',
        severity: 'success',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApplicationData.pending.type, (state) => {});
    builder.addCase(fetchApplicationData.fulfilled, (state, action) => {
      //   state.data.notas = action.payload;
      //   state.data.unidades = Array.from(
      //     new Set(action.payload.map((nota) => nota.unidade))
      //   ).sort();
    });
  },
});
export default applicationSlice.reducer;
