import React from 'react';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { Nota } from '../../data/types/Nota';
import { DataGrid, GridPagination, ptBR } from '@mui/x-data-grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import { VencimentoNota } from '../../data/enums/VencimentoNota';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { columns } from './columns';
import { fetchMonitoradosData } from './state/monitoradosFetch';
import Loading from '../../components/Loading';
import ExportToExcel from '../../components/ExportToExcel';
import GridUnidadeFiltro from '../../components/GridUnidadeFiltro';
import Page from '../../components/Page';
import { Acompanhamento } from '../../data/types/Acompanhamento';
import { createAcompanhamento } from '../nota/state/notaAction';
import { Cliente } from '../../data/types/Cliente';

const Monitorados = () => {
  const dispatch: AppDispatch = useDispatch();
  const clientes = useSelector((state: RootState) => {
    return state.parameter.data.clientes;
  })
    .filter((cliente) => cliente.monitorado)
    .sort((a, b) => a.nome.localeCompare(b.nome));

  let cnpjClientes = clientes.map((cliente) => cliente.cnpj.slice(0, 8));

  // Remove duplicatas usando filter
  let cnpjClientesUnicos = cnpjClientes.filter(
    (cnpj, index, array) => array.indexOf(cnpj) === index
  );

  var notas = useSelector((state: RootState) => state.monitorados.data.notas);
  React.useEffect(() => {
    setValue(0);
    dispatch(fetchMonitoradosData());
  }, []);

  const handleCadastrarAcompanhamento = (idNota: number, descricao: string) => {
    const acompanhamento = { descricao, idNota: idNota } as Acompanhamento;
    dispatch(createAcompanhamento(acompanhamento)).then(() => {
      dispatch(fetchMonitoradosData());
    });
  };

  const [value, setValue] = React.useState(0);

  if (notas.length === 0 || clientes.length === 0) {
    return <Loading />;
  }

  return (
    <Page title={`Clientes Monitorados (${notas.length})`}>
      <div className='h-full overflow-hidden grid grid-rows-[100px_auto] w-full'>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            backgroundColor: 'white',
            overflow: 'auto',
          }}
        >
          <Tabs
            variant='scrollable'
            onChange={(_, newValue) => setValue(newValue)}
            value={value}
          >
            {cnpjClientesUnicos.map((cnpj, index) => {
              var cliente = clientes.find(
                (cliente) => cliente.cnpj.slice(0, 8) === cnpj
              ) as Cliente;
              return (
                <Tab
                  key={index}
                  label={cliente.nome}
                  icon={
                    <div className='p-2'>
                      <Badge
                        badgeContent={
                          notas.filter(
                            (nota) =>
                              nota.vencimento === VencimentoNota.VENCIDA &&
                              (nota.cnpjPagadorFrete.slice(0, 8) === cnpj ||
                                nota.cnpjDestinatario.slice(0, 8) === cnpj ||
                                nota.cnpjRemetente.slice(0, 8) === cnpj)
                          ).length
                        }
                        color='error'
                      ></Badge>
                    </div>
                  }
                  iconPosition='bottom'
                />
              );
            })}
          </Tabs>
        </Box>

        <GridUnidadeFiltro
          notas={notas.filter(
            (nota) =>
              nota.cnpjPagadorFrete.slice(0, 8) === cnpjClientesUnicos[value] ||
              nota.cnpjDestinatario.slice(0, 8) === cnpjClientesUnicos[value] ||
              nota.cnpjRemetente.slice(0, 8) === cnpjClientesUnicos[value]
          )}
          columns={columns(handleCadastrarAcompanhamento)}
          gridName='Clientes Monitorados'
          rowHeight
        />
      </div>
    </Page>
  );
};
export default Monitorados;
