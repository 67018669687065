import { Nota } from '../data/types/Nota';
import { getRequest } from './service';

export const getHistorico = async (
  dataInicio: string,
  dataFim: string
): Promise<Nota[]> => {
  return await getRequest<Nota[]>('/historico/emissao', {
    params: {
      inicio: dataInicio,
      fim: dataFim,
    },
  });
};
