import { createSlice } from '@reduxjs/toolkit';
import { fetchParceirosData } from './parceirosAction';
import { Parceiro } from '../../../data/viewModels/Parceiro';

export interface ParceirosState {
  data: Parceiro[];
  isLoading: boolean;
}
const initialState = {
  data: {} as Parceiro[],
  unidadesFilter: [],
  isLoading: true,
} as ParceirosState;

const parceirosSlice = createSlice({
  name: 'parceiros',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchParceirosData.pending.type, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchParceirosData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
  },
});
export default parceirosSlice.reducer;
