import { getRequest, postRequest } from './service';

export type PostCarregamento = {
  notas: {
    idNota: number;
    ordemCarregamento: number;
  }[];
  observacao: string;
  idRota: number;
  data?: string;
};

export const postCarregamento = async (carregamento: PostCarregamento) => {
  return await postRequest<PostCarregamento>('/carregamento', carregamento);
};

type NotaCarregamento = {
  idNota: number;
  ordemCarregamento: number;
};

export const getNotasCarregamento = async (
  idCarregamento: number
): Promise<NotaCarregamento[]> => {
  return await getRequest<NotaCarregamento[]>(
    `/carregamento/${idCarregamento}/nota`
  );
};
