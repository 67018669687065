import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaginaPendencia } from '../../../services/PaginaService';

export const fetchPendenciaData = createAsyncThunk(
  'pendencia/fetch',
  async () => {
    var notasCarregamento = await getPaginaPendencia();
    return notasCarregamento;
  }
);
