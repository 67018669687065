import { MdEmail } from 'react-icons/md';
import { FcPhone, FcHighPriority, FcFactory } from 'react-icons/fc';
import { FormaAgendamentoEnum } from '../../../data/enums/FormaAgedamento';
import { Cliente } from '../../../data/types/Cliente';
import { Button, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  openParameter,
  openParameterTab,
} from '../../../state/application/applicationAction';
import { AppDispatch } from '../../../state/store';
import {
  openFormUpdateCliente,
  openFormUpdateClienteTab,
} from '../../parameter/pages/cliente/state/clienteAction';

type FormaAgendamentoProps = {
  cliente: Cliente;
};

const FormaAgendamento = ({ cliente }: FormaAgendamentoProps) => {
  const dispatch: AppDispatch = useDispatch();

  const handleOpenFormUpdateCliente = (cliente: Cliente) => {
    dispatch(openFormUpdateClienteTab({ cliente: cliente, tab: 1 }));
    dispatch(openParameterTab(1));
  };

  return (
    <Tooltip
      title={`${
        cliente !== undefined && cliente.formaAgendamento === null
          ? `O cliente ${cliente?.nome} não possui uma forma de agendamento cadastrada.`
          : 'Agendar'
      }`}
    >
      <div className=''>
        {cliente.formaAgendamento === null && (
          <Button
            onClick={() => handleOpenFormUpdateCliente(cliente)}
            size='small'
            variant={`${
              cliente.formaAgendamento === null ? 'text' : 'contained'
            }`}
            color='primary'
          >
            {cliente.formaAgendamento === null ? 'Cadastrar' : 'Agendar'}
          </Button>
        )}
        {cliente.formaAgendamento !== null && (
          <div>
            <div>{getFormaAgendamento(cliente)}</div>
            <div>{cliente.observacao}</div>
          </div>
        )}
      </div>
    </Tooltip>
  );
};
export default FormaAgendamento;

const getFormaAgendamento = (cliente: Cliente) => {
  if (cliente.formaAgendamento === undefined) return '';
  switch (cliente.formaAgendamento) {
    case FormaAgendamentoEnum.EMAIL:
      return (
        <div>
          <Mailto
            email={cliente.valorAgendamento}
            subject='Agendamento'
            body={``}
          >
            {cliente.valorAgendamento}
          </Mailto>
        </div>
      );
    case FormaAgendamentoEnum.TELEFONE:
      return (
        <div>
          <FcPhone />
          {cliente.valorAgendamento}
        </div>
      );
    case FormaAgendamentoEnum.PORTAL:
      return (
        <>
          Portal:{' '}
          <a target='_blank' href={cliente.valorAgendamento}>
            {cliente.valorAgendamento}
          </a>
          <div>{cliente.observacao}</div>
        </>
      );
    default:
      break;
  }
};

4;

const Mailto = ({
  email,
  subject,
  body,
  children,
}: {
  email: string;
  subject: string;
  body: string;
  children: string;
}) => {
  return (
    <a
      href={`mailto:${email}?subject=${
        encodeURIComponent(subject) || ''
      }&body=${encodeURIComponent(body) || ''}`}
    >
      {children}
    </a>
  );
};
