import { DataGrid, GridPagination, ptBR } from '@mui/x-data-grid';
import { Nota } from '../../../data/types/Nota';
import { columns } from './columns';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { AppDispatch, RootState } from '../../../state/store';
import {
  addSelectedNota,
  removeSelectedNota,
} from './formCreateEntregas/state/formCreateEntregasAction';
import { useSelector } from 'react-redux';
import { AiOutlineLoading } from 'react-icons/ai';
import { openFormUpdateClienteTab } from '../../parameter/pages/cliente/state/clienteAction';
import { openParameterTab } from '../../../state/application/applicationAction';
import { Cliente } from '../../../data/types/Cliente';

type EntregasGridProps = {
  notas: Nota[];
  cancelar: () => void;
  registrar: Function;
  filtro: string;
};

const CustomPagination = ({ cancelar, registrar }: any) => {
  const isSaving = useSelector(
    (state: RootState) => state.formCreateEntregas.isSaving
  );
  const selectedNotas = useSelector(
    (state: RootState) => state.formCreateEntregas.data.selectedNotas
  );
  return (
    <div className='flex items-center'>
      <div style={{ flexGrow: 1 }}></div>
      <div className='px-2'>
        <span className='flex gap-5'>
          <Button onClick={() => cancelar()}>Cancelar</Button>

          <Button
            sx={{ width: '100px' }}
            disabled={isSaving || selectedNotas.length === 0}
            type='submit'
            variant='contained'
            color='primary'
            onClick={() => registrar()}
          >
            {!isSaving ? (
              <span>Registrar</span>
            ) : (
              <span>
                <AiOutlineLoading className='animate-spin text-2xl' />
              </span>
            )}
          </Button>
        </span>
      </div>
    </div>
  );
};

const EntregasGrid = ({
  notas,
  cancelar,
  registrar,
  filtro,
}: EntregasGridProps) => {
  const dispatch: AppDispatch = useDispatch();

  const addSelectedNotaHandler = (nota: Nota) => {
    dispatch(addSelectedNota(nota));
  };

  const removeSelectedNotaHandler = (nota: Nota) => {
    dispatch(removeSelectedNota(nota));
  };

  const clientes = useSelector(
    (state: RootState) => state.parameter.data.clientes
  );

  const handleOpenFormUpdateCliente = (cliente: Cliente) => {
    dispatch(openFormUpdateClienteTab({ cliente: cliente, tab: 1 }));
    dispatch(openParameterTab(1));
  };

  return (
    <DataGrid
      slots={{
        pagination: () => (
          <CustomPagination cancelar={cancelar} registrar={registrar} />
        ),
      }}
      disableRowSelectionOnClick
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      rows={notas}
      getRowId={(nota) => {
        return nota.idNota;
      }}
      columns={columns(
        dispatch,
        addSelectedNotaHandler,
        removeSelectedNotaHandler,
        clientes,
        handleOpenFormUpdateCliente
      )}
      getRowClassName={(params) =>
        params.row.numeroNotaFiscal.includes(filtro) && filtro !== ''
          ? 'bg-[#FDF7D0]'
          : ''
      }
      onCellKeyDown={(_, events) => events.stopPropagation()}
    />
  );
};
export default EntregasGrid;
