import { IoMdCloseCircle } from 'react-icons/io';

type FecharProps = {
  titulo: string;
  onClick: () => void;
  className?: string;
  dark?: boolean;
};

const Fechar = ({ titulo, onClick, dark }: FecharProps) => {
  return (
    <div className='w-full'>
      <div
        className={`flex w-full ${
          dark ? 'text-gray-200' : 'text-primary-500'
        } `}
      >
        <div className='font-bold uppercase'>{titulo}</div>
        <div
          onClick={() => onClick()}
          className='ml-auto cursor-pointer flex gap-1 items-center'
        >
          <div className='text-[12px] font-thin'>FECHAR</div>
          <IoMdCloseCircle className='text-2xl' />
        </div>
      </div>
    </div>
  );
};
export default Fechar;
