export enum FormaAgendamentoEnum {
  EMAIL = 'Email',
  TELEFONE = 'Telefone',
  PORTAL = 'Portal',
  REMETENTE = 'Remetente',
}

export const getEnum = (str: string): FormaAgendamentoEnum | undefined => {
  for (const key in FormaAgendamentoEnum) {
    if (
      FormaAgendamentoEnum.hasOwnProperty(key) &&
      FormaAgendamentoEnum[key as keyof typeof FormaAgendamentoEnum] === str
    ) {
      return FormaAgendamentoEnum[key as keyof typeof FormaAgendamentoEnum];
    }
  }
  return undefined;
};
