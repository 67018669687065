import { createSlice } from '@reduxjs/toolkit';
import { Nota } from '../../../data/types/Nota';
import { fetchCarregamentoData } from './carregamentoFetch';
import { Unidade } from '../../../data/types/Unidade';

export interface CarregamentoState {
  state: 'loading' | 'success' | 'error';
  openTab: number;
  data: {
    notas: Nota[];
    unidades: string[];
  };
}

const initialState = {
  state: 'loading',
  openTab: 0,
  data: {
    notas: [],
    unidades: [],
  },
} as CarregamentoState;

const carregamentoSlice = createSlice({
  name: 'carregamento',
  initialState,
  reducers: {
    openTab: (state, action) => {
      state.openTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarregamentoData.pending.type, (state) => {
      if (state.data.notas.length === 0) {
        state.state = 'loading';
      }
    });
    builder.addCase(fetchCarregamentoData.fulfilled, (state, action) => {
      state.state = 'success';
      state.data.notas = action.payload;
      state.data.unidades = Array.from(
        new Set(action.payload.map((nota) => nota.unidade))
      ).sort();
    });
  },
});
export default carregamentoSlice.reducer;
