import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { useNavigate } from 'react-router-dom';
import * as CarregamentoAction from './state/carregamentoAction';
import { openCarretamento } from '../buildCarregamento/buildCarregamentoAction';
import {
  calculatePesoReal,
  calculatePesoCubado,
  calculatmMetroCubico as calculateMetroCubico,
  calculateFrete,
  calculateFretePorKilo,
} from '../../utils/calculate';
import {
  formatPeso,
  formatMetroCubico,
  formatCurrency,
  formatNumber,
} from '../../utils/formatter';
import StatusNotas from '../buildCarregamento/components/StatusNotas';
import Summary from './components/Summary';
import { VencimentoNota } from '../../data/enums/VencimentoNota';
import { fetchCarregamentoData } from './state/carregamentoFetch';

import { Rota } from '../../data/types/Rota';
import Loading from '../../components/Loading';

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = ({ value, index, children }: TabPanelProps) => {
  return (
    <div hidden={value !== index} className='h-full'>
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
};

const Carregamento = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const tabIndex = useSelector(
    (state: RootState) => state.carregamento.openTab
  );

  const parametros = useSelector(
    (state: RootState) => state.parameter.data.parametros
  );
  var notas = useSelector((state: RootState) => state.carregamento.data.notas);

  const unidadeArray = useSelector(
    (state: RootState) => state.carregamento.data.unidades
  );

  const [value, setValue] = React.useState(tabIndex);

  React.useEffect(() => {
    if (tabIndex !== undefined) {
      setValue(tabIndex);
    }
  }, [tabIndex]);

  React.useEffect(() => {
    dispatch(fetchCarregamentoData());
  }, []);

  const openTab = (index: number) => {
    setValue(index);
    dispatch(CarregamentoAction.openTab(index));
  };

  const openCarregamento = (unidade: string, rota: Rota) => {
    if (window.matchMedia('(min-width: 641px)').matches) {
      dispatch(openCarretamento({ unidade, rota }));
      navigate('/selecionarCarregamento');
    }
  };

  if (notas.length === 0) {
    return <Loading />;
  }

  return (
    <div className='w-full h-full overflow-hidden'>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: 'white',
        }}
      >
        <Tabs
          variant='scrollable'
          scrollButtons
          value={value}
          onChange={(event, newValue) => openTab(newValue)}
        >
          {unidadeArray.map((unidade, index) => (
            <Tab
              iconPosition='end'
              icon={
                <div
                  hidden={
                    notas.filter(
                      (nota) =>
                        nota.vencimento === VencimentoNota.VENCIDA &&
                        nota.unidade === unidade
                    ).length === 0
                  }
                  className='w-2 h-2 rounded-full bg-vencido'
                ></div>
              }
              key={index}
              label={unidade}
            />
          ))}
        </Tabs>
      </Box>
      {unidadeArray.map((unidade, index) => {
        const notasUnidade = notas.filter((nota) => nota.unidade === unidade);

        const rotaIdsSet = new Set<number>();

        notasUnidade.forEach((nota) => {
          // Check if 'nota.rota' exists and is not undefined
          if (
            nota.rota !== undefined &&
            nota.rota !== null
            // nota.rota.unidade.nome === unidade
          ) {
            rotaIdsSet.add(nota.rota.idRota);
          }
        });
        const rotaArray = Array.from(rotaIdsSet)
          .map((idRota) => {
            // Find the 'rota' object corresponding to the 'idRota'
            return notas.find(
              (nota) => nota.rota && nota.rota.idRota === idRota
            )?.rota;
          })
          .filter((rota) => rota !== undefined) as Rota[];

        return (
          <TabPanel key={index} value={value} index={index}>
            <div className='grid grid-cols-[100%_0%] xl:grid-cols-[60%_40%] h-full'>
              <div className='grid overflow-auto gap-4 xs:grid-cols-carregamentoCell md:grid-cols-carregamento py-5 content-start justify-center pb-[100px]'>
                {rotaArray.map((rota, index) => {
                  const notasRota = notasUnidade.filter(
                    (nota) =>
                      nota.rota?.idRota === rota.idRota &&
                      nota.unidade === unidade
                  );
                  return (
                    <div
                      key={index}
                      onClick={() => openCarregamento(unidade, rota)}
                      className='cursor-pointer w-full shadow-lg hover:shadow-xl border-solid border border-gray-200 bg-white grid grid-rows-[40px_150px]'
                    >
                      <div className='rounded-t-lg flex text-lg font-bold items-center pl-4 w-full h-10 bg-primary-500 text-gray-100'>
                        {/* TODO: Create a function for returning the string */}
                        {rota.nome.toUpperCase()} ({notasRota.length})
                      </div>
                      <div className='w-full p-4 flex items-center justify-center flex-col space-y-2'>
                        <div className='h-34 items-baseline text-gray-600'>
                          <span className='md:text-4xl font-extrabold'>
                            {formatPeso(calculatePesoReal(notasRota), 0)}
                          </span>
                          <span>
                            ({formatPeso(calculatePesoCubado(notasRota), 0)} /{' '}
                            {formatMetroCubico(
                              calculateMetroCubico(notasRota),
                              0
                            )}
                            )
                          </span>
                        </div>
                        <div className='text-green-600 font-bold'>
                          <span className='font-bold'>FRETE: </span>
                          {formatCurrency(calculateFrete(notasRota), 2)}
                          {' / '}(
                          {formatNumber(
                            calculateFretePorKilo(
                              calculatePesoCubado(notasRota),
                              calculatePesoReal(notasRota),
                              calculateFrete(notasRota)
                            ),
                            2
                          )}
                          )
                        </div>
                        <div>
                          <StatusNotas notas={notasRota} />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className='overflow-auto'>
                <Summary notas={notasUnidade} unidadeAtual={unidade} />
              </div>
            </div>
          </TabPanel>
        );
      })}
    </div>
  );
};
export default Carregamento;
