import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Cliente } from '../../../../../data/types/Cliente';
import * as ClienteService from '../../../../../services/ClienteService';
import { fetchParameterData } from '../../../state/parameterFetch';
import { openSnackbar } from '../../../../../state/application/applicationAction';

export const openFormUpdateCliente = createAction<Cliente>(
  'cliente/openFormUpdateCliente'
);
export const closeFormUpdateCliente = createAction(
  'cliente/closeFormUpdateCliente'
);
export const setTab = createAction<number>('cliente/setTab');
export const saving = createAction('cliente/saving');
export const saved = createAction('cliente/saved');

export const openFormUpdateClienteTab = createAsyncThunk(
  'cliente/updateCliente',
  async (
    formUpdateCliente: { cliente: Cliente; tab: number },
    { dispatch }
  ) => {
    dispatch(openFormUpdateCliente(formUpdateCliente.cliente));
    dispatch(setTab(formUpdateCliente.tab));
  }
);

export const updateCliente = createAsyncThunk(
  'cliente/updateCliente',
  async (cliente: Cliente, { dispatch }) => {
    dispatch(saving());
    const response = await ClienteService.updateCliente(cliente);
    dispatch(fetchParameterData()).then(() => {
      dispatch(
        openSnackbar({
          message: 'Cliente salvo com sucesso',
          severity: 'success',
        })
      );
      dispatch(openFormUpdateCliente(cliente));
      dispatch(saved());
    });
    return response;
  }
);
