import { createSlice, current } from '@reduxjs/toolkit';
import { fetchAgendamentoData } from './agendamentoFetch';
import { Nota } from '../../../data/types/Nota';

export interface AgendamentoState {
  unidadesFilter: string[];
  isLoading: boolean;
  data: {
    notas: Nota[];
    unidades: string[];
  };
}
const initialState = {
  unidadesFilter: [],
  isLoading: false,
  data: {
    notas: [],
    unidades: [],
  },
} as AgendamentoState;

const agendamentoSlice = createSlice({
  name: 'agendamento',
  initialState,
  reducers: {
    changeUnidadeFilter: (state, action) => {
      state.unidadesFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAgendamentoData.pending.type, (state) => {
      if (state.data.notas.length === 0) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchAgendamentoData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.notas = action.payload;
    });
  },
});
export default agendamentoSlice.reducer;
