type PageProps = {
  title: string;
  children: React.ReactNode;
};

const Page = ({ title, children }: PageProps) => {
  return (
    <div className={`p-5 w-full h-full grid grid-rows-[60px_auto]`}>
      <div className={`text-2xl`}>{title}</div>
      <div className='h-full w-full overflow-hidden'>
        <div className='h-full w-full overflow-hidden'>{children}</div>
      </div>
    </div>
  );
};
export default Page;
