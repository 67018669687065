import React from 'react';

interface GaugeProps {
  value: number; // Valor atual do gauge
  maxValue?: number; // Valor máximo do gauge (padrão: 100)
  size?: number; // Tamanho do gauge
  strokeWidth?: number; // Largura do arco do gauge
}

const Gauge: React.FC<GaugeProps> = ({
  value,
  maxValue = 100,
  size = 200,
  strokeWidth = 15,
}) => {
  const radius = (size - strokeWidth) / 2; // Raio do arco
  const circumference = Math.PI * radius; // Circunferência total do arco
  const arcLength = (value / maxValue) * circumference; // Comprimento do arco com base no valor

  const centerX = size / 2;
  const centerY = size; // Ajuste para que o arco fique no eixo X

  const cor = value > 80 ? '#3CB371' : value > 60 ? '#FFD700' : '#FF0000';

  return (
    <svg
      className='mt-[-130px]'
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      {/* Círculo de fundo */}
      <circle
        cx={centerX}
        cy={centerY}
        r={radius}
        stroke='#ddd' // Cor do fundo
        strokeWidth={strokeWidth}
        fill='none'
        transform={`rotate(180 ${centerX} ${centerY})`} // Rotaciona para que comece do eixo X
      />
      {/* Arco do Gauge */}
      <circle
        cx={centerX}
        cy={centerY}
        r={radius}
        stroke={cor} // Cor do arco
        strokeWidth={strokeWidth}
        fill='none'
        strokeDasharray={`${arcLength}, ${circumference}`} // Comprimento do arco atual
        transform={`rotate(180 ${centerX} ${centerY})`} // Rotaciona o arco para começar no eixo X
        style={{ transition: 'stroke-dasharray 0.5s ease' }}
      />
      {/* Texto com o valor */}
      <text
        x={centerX}
        y={centerY - 10} // Ajuste a posição vertical do texto
        textAnchor='middle'
        dy='0.35em'
        fontSize='1.5em'
        fill='#333'
      >
        {Math.round((value / maxValue) * 100)}%
      </text>
    </svg>
  );
};

export default Gauge;
