import NotaFiscal from '../../components/NotaFiscal';
import { calculateDiasEntreDatas } from '../../utils/calculate';
import { formatCurrency, formatDate } from '../../utils/formatter';
import { Nota } from '../../data/types/Nota';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import NumeroNotaFiscal from '../../components/NumeroNotaFiscal';

const dataAtual = new Date().toISOString().split('T')[0];

export const columns = [
  {
    field: 'numeroNotaFiscal',
    headerName: 'NF',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<Nota>) => {
      return <NumeroNotaFiscal nota={row} />;
    },
  },
  {
    field: 'dataAutorizacao',
    headerName: 'Emissão',
    flex: 1,
    renderCell: (params: any) => {
      return <div>{formatDate(params.row.dataAutorizacao)}</div>;
    },
  },
  { field: 'remetente', headerName: 'Remetente', flex: 2 },
  { field: 'destinatario', headerName: 'Destinatário', flex: 2 },
  { field: 'cidade', headerName: 'Cidade', flex: 1 },
  {
    field: 'unidade',
    headerName: 'Unidade',
    flex: 0.5,
    renderCell: ({ row }: GridRenderCellParams<Nota>) => {
      return <div>{row.rota?.unidade.nome}</div>;
    },
  },
  {
    field: 'dataUltimaOcorrencia',
    headerName: 'Dias Retido',
    flex: 1,
    renderCell: (params: any) => {
      const qtdDias = calculateDiasEntreDatas(
        params.row.dataUltimaOcorrencia != null
          ? params.row.dataUltimaOcorrencia
          : dataAtual,
        dataAtual
      );
      var cor = 'black';
      if (qtdDias > 2) {
        cor = 'red';
      }
      return (
        <div>
          <span className={'text-' + cor + '-600'}>{qtdDias}</span>
          <span className='text-[11px]'>
            {' '}
            ({formatDate(params.row.dataUltimaOcorrencia)})
          </span>
        </div>
      );
    },
  },
  {
    field: 'valorMercadoria',
    headerName: 'Valor Mercadoria',
    flex: 0.8,
    type: 'number',
    renderCell: (params: any) => (
      <div className='text-red-600'>
        {formatCurrency(params.row.valorMercadoria, 2)}
      </div>
    ),
  },
] as GridColDef<Nota>[];
