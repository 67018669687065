import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { GoGrabber } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { changeOrdemCarregamento } from '../buildCarregamentoAction';
type SortProps = {
  ordemCarregamento: string[];
};

const Sort = (props: SortProps) => {
  const ordemCarregamento = props.ordemCarregamento;
  const dispatch = useDispatch();

  return (
    <div className='overflow-auto h-full'>
      <div className='p-5 text-gray-600'>
        <h3>Ordem da Rota:</h3>
      </div>
      <DragDropContext
        onDragEnd={(result) =>
          dispatch(
            changeOrdemCarregamento({
              index: result.source.index,
              destination: result.destination?.index,
            })
          )
        }
      >
        <Droppable droppableId='cidades'>
          {(provided) => (
            <TableContainer>
              <Table size='small'>
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {ordemCarregamento.map((cidade, index) => {
                    return (
                      <Draggable
                        key={cidade}
                        draggableId={cidade}
                        index={index}
                      >
                        {(provided) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TableCell width='20px'>
                              <GoGrabber />
                            </TableCell>
                            <TableCell>{cidade}</TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    );
                  })}
                  <TableRow>
                    <TableCell
                      sx={{ height: '20px', border: 'none' }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default Sort;
