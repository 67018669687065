import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Unidade } from '../../../../../data/types/Unidade';
import { openSnackbar } from '../../../../../state/application/applicationAction';
import * as UnidadeService from '../../../../../services/UnidadeService';
import { fetchParameterData } from '../../../state/parameterFetch';

export const openForm = createAction<string>('unidade/openForm');
export const openFormUpdate = createAction<{
  unidade: Unidade;
  component: string;
}>('unidade/openFormUpdate');
export const saving = createAction('unidade/saving');
export const saved = createAction('unidade/saved');
export const closeForm = createAction('unidade/closeForm');

export const updateUnidade = createAsyncThunk(
  'unidade/updateUnidade',
  async (unidade: Unidade, thunkApi) => {
    thunkApi.dispatch(saving());
    await UnidadeService.updateUnidade(unidade)
      .then((data) => {
        thunkApi.dispatch(fetchParameterData()).then((data) => {
          thunkApi.dispatch(
            openSnackbar({
              message: 'Unidade salva com sucesso',
              severity: 'success',
            })
          );
          thunkApi.dispatch(saved());
        });
      })
      .catch((error) => {
        thunkApi.dispatch(
          openSnackbar({
            message: 'Erro ao salvar unidade',
            severity: 'error',
          })
        );
        thunkApi.dispatch(saved());
      });

    return {};
  }
);

export const createUnidade = createAsyncThunk(
  'unidade/createUnidade',
  async (unidade: Unidade, thunkApi) => {
    thunkApi.dispatch(saving());
    await UnidadeService.createUnidade(unidade)
      .then((data) => {
        thunkApi.dispatch(fetchParameterData()).then((data) => {
          thunkApi.dispatch(
            openSnackbar({
              message: 'Unidade salva com sucesso',
              severity: 'success',
            })
          );
          thunkApi.dispatch(saved());
          thunkApi.dispatch(closeForm());
        });
      })
      .catch((error) => {
        thunkApi.dispatch(
          openSnackbar({
            message: 'Erro ao salvar unidade',
            severity: 'error',
          })
        );
        thunkApi.dispatch(saved());
      });

    return {};
  }
);

export const deleteUnidade = createAsyncThunk(
  'unidade/createUnidade',
  async (idUnidade: Number, thunkApi) => {
    await UnidadeService.deleteUnidade(idUnidade)
      .then((data) => {
        thunkApi.dispatch(fetchParameterData()).then((data) => {
          thunkApi.dispatch(
            openSnackbar({
              message: 'Unidade deletada com sucesso',
              severity: 'success',
            })
          );
        });
      })
      .catch((error) => {
        thunkApi.dispatch(
          openSnackbar({
            message: 'Erro ao deletar unidade',
            severity: 'error',
          })
        );
      });

    return {};
  }
);
