import applicationReducer from './application/applicationSlice';
import RotaReducer from '../pages/parameter/pages/rota/state/rotaSlice';
import SetorReducer from '../pages/parameter/pages/setor/state/setorSlice';
import UnidadeReducer from '../pages/parameter/pages/unidade/state/unidadeSlice';
import CidadeReducer from '../pages/parameter/pages/cidade/state/cidadeSlice';
import BuildCarregamentoReducer from './../pages/buildCarregamento/buildCarregamentoSlice';
import CarregamentoReducer from '../pages/carregamento/state/carregamentoSlice';
import ClienteReducer from '../pages/parameter/pages/cliente/state/clienteSlice';
import AtrasadasReducer from '../pages/atrasadas/state/atrasadasSlice';
import PendenciaReducer from '../pages/pendencia/state/pendenciaSlice';
import EntregasReducer from '../pages/entregas/state/entregasSlice';
import FormCreateEntregasReducer from '../pages/entregas/components/formCreateEntregas/state/formCreateEntregasSlice';
import ParametroReducer from '../pages/parameter/pages/parametro/state/parametroSlice';
import CanhotoReducer from '../pages/canhotos/state/canhotosSlice';
import MonitoradoReducer from '../pages/monitorados/state/monitoradosSlice';
import ParceirosReducer from '../pages/parceiros/state/parceirosSlice';
import AgendamentoReducer from '../pages/agendamento/state/agendamentoSlice';
import AgendadasReducer from '../pages/agendadas/state/agendadasSlice';
import DashboardReducer from '../pages/dashboard/dashboardSlice';
import ParameterReducer from '../pages/parameter/state/parameterSlice';
import UsuarioReducer from '../pages/parameter/pages/usuario/state/usuarioSlice';
import NotaReducer from '../pages/nota/state/notaSlice';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

const reducer = combineReducers({
  application: applicationReducer,
  rota: RotaReducer,
  cidade: CidadeReducer,
  buildCarregamento: BuildCarregamentoReducer,
  carregamento: CarregamentoReducer,
  cliente: ClienteReducer,
  atrasadas: AtrasadasReducer,
  pendencia: PendenciaReducer,
  parametro: ParametroReducer,
  canhotos: CanhotoReducer,
  dashboard: DashboardReducer,
  monitorados: MonitoradoReducer,
  parceiros: ParceirosReducer,
  agendamento: AgendamentoReducer,
  entregas: EntregasReducer,
  formCreateEntregas: FormCreateEntregasReducer,
  parameter: ParameterReducer,
  usuario: UsuarioReducer,
  nota: NotaReducer,
  setor: SetorReducer,
  unidade: UnidadeReducer,
  agendadas: AgendadasReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof reducer>;
