import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Rota } from '../../data/types/Rota';
import { SnackbarType } from '../../components/CustomizedSnackbar';
import { Cidade } from '../../data/types/Cidade';
import { Cliente } from '../../data/types/Cliente';
import { Ocorrencia } from '../../data/types/Ocorrencia';
import { setTab } from '../../pages/parameter/state/parameterAction';
import * as FeedbackService from '../../services/FeedbackService';

export const openParameter = createAction<boolean | undefined>(
  'application/openParameter'
);
export const closeParameter = createAction('application/closeParameter');
export const openSnackbar = createAction<SnackbarType>(
  'application/openSnackbar'
);
export const closeSnackbar = createAction('application/closeSnackbar');
export const isMobile = createAction('application/isMobile');
export const isWeb = createAction('application/isWeb');
export const updateRota = createAction<Rota>('application/updateRota');
export const updateCidade = createAction<Cidade>('application/updateCidade');
export const updateCliente = createAction<Cliente>('application/updateCliente');
export const updateOcorrencia = createAction<Ocorrencia>(
  'application/updateOcorrencia'
);
export const openParameterTab = createAsyncThunk(
  'application/openParameterTab',
  async (tab: number, { dispatch }) => {
    dispatch(setTab(tab));
    dispatch(openParameter(true));
  }
);

export const createFeedback = createAsyncThunk(
  'application/createFeedback',
  async ({ nota, texto }: { nota: number; texto: string }, thunkApi) => {
    await FeedbackService.createFeedback(nota, texto).then(() => {
      thunkApi.dispatch(
        openSnackbar({
          message: 'Obrigado pelo seu feedback!',
          severity: 'success',
        })
      );
    });
  }
);
