import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaginaAgendamento } from '../../../services/PaginaService';

export const fetchAgendamentoData = createAsyncThunk(
  'agendamento/fetch',
  async () => {
    var notasCarregamento = await getPaginaAgendamento();
    return notasCarregamento;
  }
);
