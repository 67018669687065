import { Nota } from '../data/types/Nota';
import { convertToDate } from './conversor';

export const calculatePesoReal = (notas: Nota[]) => {
  return notas.reduce((acc, nota: Nota) => {
    return acc + nota.pesoReal;
  }, 0);
};

export const calculatePesoCubado = (notas: Nota[]) => {
  return notas.reduce((acc, nota: Nota) => {
    return acc + nota.pesoCubado;
  }, 0);
};

export const calculatmMetroCubico = (notas: Nota[]) => {
  return notas.reduce((acc, nota: Nota) => {
    return acc + nota.pesoCubado / 300;
  }, 0);
};

export const calculateFrete = (notas: Nota[]) => {
  return notas.reduce((acc, nota: Nota) => {
    return acc + nota.valorFrete;
  }, 0);
};

export const calculateFretePorKilo = (
  pesoCubado: number,
  pesoReal: number,
  valorFrete: number
) => {
  return valorFrete / Math.max(pesoCubado, pesoReal);
};

export const calculatePercent = (value: number, total: number) => {
  return (value / total) * 100;
};

export const calculateDiasEntreDatas = (
  dateInicial: string,
  dateFinal: string
) => {
  const dataInicial = new Date(dateInicial);
  const dataFinal = new Date(dateFinal);
  const diferenca = dataFinal.getTime() - dataInicial.getTime();
  return Math.ceil(diferenca / (1000 * 3600 * 24));
};

export const getPeso = (nota: Nota) => {
  if (nota.pesoReal > nota.pesoCubado) {
    return nota.pesoReal;
  }
  return nota.pesoCubado;
};
