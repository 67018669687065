import { Button } from '@mui/material';
import React from 'react';
import * as XLSX from 'xlsx';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { formatDate } from '../utils/formatter';
import { getCurrentDateTimeInBRTFormat } from '../utils/date';

interface ExportToExcelProps {
  data: any[];
  fileName: string;
  columns?: any;
}

const ExportToExcel = ({ data, fileName, columns }: ExportToExcelProps) => {
  var notas = data;
  console.log(columns);
  if (columns) {
    notas = data.map((obj) => {
      return columns.reduce((acc: { [key: string]: any }, column: any) => {
        if (obj.hasOwnProperty(column.field)) {
          //Formata data
          const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
          let value = obj[column.field];
          if (regex.test(value)) {
            value = formatDate(value);
          }
          acc[column.header] = value;
        }
        return acc;
      }, {});
    });
  }

  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the data array to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(notas);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a binary string representing the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Create a Blob from the binary string
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Create a link element and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName} - ${getCurrentDateTimeInBRTFormat()}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (fileName && data.length > 0) {
    return (
      <button onClick={exportToExcel}>
        <div className='flex items-center justify-center text-sm gap-2 text-[#3B82F6]'>
          <RiFileExcel2Fill className='text-2xl' /> Exportar Excel
        </div>
      </button>
    );
  } else {
    return <div></div>;
  }
};

export default ExportToExcel;
