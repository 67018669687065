import { useSelector } from 'react-redux';
import Page from '../../components/Page';
import { AppDispatch, RootState } from '../../state/store';
import { TipoUnidade } from '../../data/enums/TipoUnidade';
import React, { useEffect } from 'react';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import GridUnidadeFiltro from '../../components/GridUnidadeFiltro';
import { columns } from './columns';
import { useDispatch } from 'react-redux';
import { fetchParceirosData } from './state/parceirosAction';
import Loading from '../../components/Loading';
import { openParameterTab } from '../../state/application/applicationAction';
import { formatNumber } from '../../utils/formatter';
import Gauge from '../../components/Gauge';
import { columnsAbertas } from './columnsAbertas';

const Parceiros = () => {
  const dispatch: AppDispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const parceiros = useSelector((state: RootState) => state.parceiros.data);
  const unidades = useSelector(
    (state: RootState) => state.parameter.data.unidades
  );
  const isLoading = useSelector(
    (state: RootState) => state.parceiros.isLoading
  );
  useEffect(() => {
    dispatch(fetchParceirosData());
  }, []);

  useEffect(() => {
    dispatch(fetchParceirosData());
  }, [unidades]);

  if (isLoading) {
    return <Loading />;
  } else {
    if (parceiros.length === 0) {
      return (
        <Page title=''>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            height='100%'
            gap={3}
          >
            <Typography variant='h5' align='center' color='textPrimary'>
              Você ainda não tem nenhuma unidade parceira cadastrada.
            </Typography>
            <Typography
              variant='body1'
              align='center'
              color='textSecondary'
              className='w-[70%]'
            >
              Mas não se preocupe, vou te ajudar! Para começar, clique no botão
              abaixo para acessar a tela de configuração de unidades. Lá, você
              pode cadastrar novas unidades ou editar as existentes, definindo o
              tipo como 'Parceiro'.
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => dispatch(openParameterTab(5))}
            >
              Abrir Configuração de Unidades
            </Button>
          </Box>
        </Page>
      );
    }
  }

  return (
    <Page title={`Parceiros`}>
      <div className='h-full overflow-hidden grid grid-rows-[auto_auto_auto] w-full gap-5'>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            backgroundColor: 'white',
            overflow: 'auto',
          }}
        >
          <Tabs
            variant='scrollable'
            onChange={(_, newValue) => setValue(newValue)}
            value={value}
          >
            {parceiros.map((parceiro, index) => {
              return (
                <Tab
                  key={index}
                  label={
                    <div>
                      <div>
                        {parceiro.alias
                          ? `${parceiro.alias} (${parceiro.unidade})`
                          : `${parceiro.unidade}`}
                      </div>
                      <div
                        className={`text-white rounded-md ${
                          parceiro.performance.percentualAtrasoParceiro > 80
                            ? 'bg-[#3CB371]'
                            : value > 60
                            ? 'bg-[#FFD700]'
                            : 'bg-[#FF0000]'
                        }`}
                      >
                        {formatNumber(
                          parceiro.performance.percentualAtrasoParceiro,
                          0
                        )}{' '}
                        %
                      </div>
                    </div>
                  }
                />
              );
            })}
          </Tabs>
        </Box>
        <div className={`flex flex-col p-5 gap-5 bg-white`}>
          Performance do Parceiro em Outubro:
          <div className={`grid items-center grid-cols-2 w-full`}>
            <div className={`grid grid-rows-2 grid-cols-10 gap-5 `}>
              <div className={`col-span-2 justify-center flex`}>
                Total Entregas
              </div>
              <div className={`col-span-2 justify-center flex`}>No Prazo</div>
              <div className={`col-span-2 justify-center flex`}>Atrasadas</div>
              <div className={`col-span-2 justify-center flex`}>
                Resp. Transp.
              </div>
              <div className={`col-span-2 justify-center flex`}>
                Resp. Parceiro
              </div>

              <div className={`col-span-2 justify-center flex font-bold`}>
                {parceiros[value].performance.totalEntregas}
              </div>
              <div className={`col-span-2 justify-center flex font-bold`}>
                {formatNumber(
                  parceiros[value].performance.percentualNoPrazo,
                  2
                )}
                %{` (${parceiros[value].performance.noPrazo})`}
              </div>
              <div className={`col-span-2 justify-center flex font-bold`}>
                {formatNumber(
                  parceiros[value].performance.percentualAtrasadas,
                  2
                )}
                %{` (${parceiros[value].performance.atrasadas})`}
              </div>
              <div className={`col-span-2 justify-center flex font-bold`}>
                {formatNumber(
                  parceiros[value].performance.percentualResponsabilidadeMatriz,
                  2
                )}
                %{` (${parceiros[value].performance.responsabilidadeMatriz})`}
              </div>
              <div className={`col-span-2 justify-center flex font-bold`}>
                {formatNumber(
                  parceiros[value].performance
                    .percentualResponsabilidadeParceiro,
                  2
                )}
                %{` (${parceiros[value].performance.responsabilidadeParceiro})`}
              </div>
            </div>
            <div className='flex items-center gap-20 w-full justify-center'>
              <span className='text-2xl'>Performance do Parceiro: </span>
              <Gauge
                value={parceiros[value].performance.percentualAtrasoParceiro}
              />
            </div>
          </div>
        </div>
        <div className={`h-full w-full grid grid-cols-1 gap-5 overflow-auto`}>
          <GridUnidadeFiltro
            notas={parceiros[value].notasFinalizadas}
            columns={columns}
            gridName={`Notas Finalizadas ${parceiros[value].unidade}`}
          />
          {/* <GridUnidadeFiltro
            notas={parceiros[value].notasAbertas}
            columns={columnsAbertas}
            gridName='Notas em Aberto'
          /> */}
        </div>
      </div>
    </Page>
  );
};
export default Parceiros;
