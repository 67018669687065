import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import {
  Divider,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../state/store';
import React from 'react';
import { closeForm, updateUnidade } from './state/unidadeAction';
import { AiOutlineLoading } from 'react-icons/ai';
import Fechar from '../../../../components/Fechar';

const FormUpdateUnidade = () => {
  const dispatch: AppDispatch = useDispatch();

  const unidade = useSelector(
    (state: RootState) => state.unidade.updateForm.unidade
  );
  const [alias, setAlias] = React.useState('');
  const [nome, setNome] = React.useState('');
  const [prazoEntrega, setPrazoEntrega] = React.useState(0);
  const [tipoUnidade, setTipoUnidade] = React.useState(0);

  const isSaving = useSelector((state: RootState) => state.unidade.isSaving);

  React.useEffect(() => {
    setAlias(unidade.alias);
    setNome(unidade.nome);
    setPrazoEntrega(unidade.prazoEntrega);
    setTipoUnidade(unidade.tipoUnidade.idTipoUnidade);
  }, [unidade]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    dispatch(
      updateUnidade({
        ...unidade,
        alias,
        nome,
        prazoEntrega,
        tipoUnidade: { ...unidade.tipoUnidade, idTipoUnidade: tipoUnidade },
      })
    );
    return false;
  };

  return (
    <div key={unidade.idUnidade} className='h-full p-10 flex flex-col gap-5'>
      <Fechar titulo={unidade.nome} onClick={() => dispatch(closeForm())} />
      <Divider />
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <div className='flex flex-col gap-5'>
            <TextField
              onChange={(e) => setNome(e.target.value)}
              label='Sigla'
              id='sigla'
              required
              name='sigla'
              inputProps={{
                maxLength: 3,
                style: { textTransform: 'uppercase' },
              }}
              fullWidth
              defaultValue={unidade.nome}
              helperText='Sigla da unidade, formada por 3 letras em caixa alta. Ex.: ABC, XYZ'
            />
            <TextField
              onChange={(e) => setAlias(e.target.value)}
              label='Alias'
              id='alias'
              name='alias'
              fullWidth
              defaultValue={unidade.alias}
              helperText='Nome descritivo da unidades. Ex.: Unidade XPTO'
            />
            <TextField
              onChange={(e) => setPrazoEntrega(+e.target.value)}
              label='Prazo de Entrega'
              type='number'
              id='prazoEntrega'
              name='prazoEntrega'
              fullWidth
              defaultValue={unidade.prazoEntrega}
              helperText='Prazo de entrega da unidade em dias úteis'
            />
            <FormLabel>Tipo de Unidade</FormLabel>
            <RadioGroup
              defaultValue={unidade.tipoUnidade.idTipoUnidade}
              onChange={(e) => setTipoUnidade(+e.target.value)}
            >
              <FormControlLabel value={1} control={<Radio />} label='Filial' />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label='Parceiro'
              />
            </RadioGroup>
            <div
              color='primary'
              className='flex gap-5  justify-end w-full relative bottom-0 right-0'
            >
              <Button disabled={isSaving} onClick={() => dispatch(closeForm())}>
                Cancelar
              </Button>

              <Button
                sx={{ width: '100px' }}
                disabled={isSaving}
                type='submit'
                variant='contained'
                color='primary'
              >
                {!isSaving ? (
                  <span>Salvar</span>
                ) : (
                  <span>
                    <AiOutlineLoading className='animate-spin text-2xl' />
                  </span>
                )}
              </Button>
            </div>
          </div>
        </FormControl>
      </form>
    </div>
  );
};
export default FormUpdateUnidade;
