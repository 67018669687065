import { createSlice } from '@reduxjs/toolkit';
import { Parametro } from '../../../../../data/types/Parametro';

export interface ParametroState {
  updateForm: {
    isOpen: boolean;
    parametro: Parametro;
    isSaving: boolean;
  };
}

const initialState = {
  updateForm: {
    isOpen: false,
    parametro: {},
    isSaving: false,
  },
} as ParametroState;

const parametroSlice = createSlice({
  name: 'parametro',
  initialState,
  reducers: {
    saving: (state) => {
      state.updateForm.isSaving = true;
    },
    saved: (state) => {
      state.updateForm.isSaving = false;
    },
    openFormUpdateParametro: (state, action) => {
      state.updateForm.isOpen = true;
      state.updateForm.parametro = action.payload;
    },
    closeFormUpdateParametro: (state) => {
      state.updateForm.isOpen = false;
    },
  },
});
export default parametroSlice.reducer;
