import { createSlice } from '@reduxjs/toolkit';
import { Setor } from '../../../../../data/types/Setor';

export interface SetorState {
  form: {
    isOpen: boolean;
    component: string;
  };
  updateForm: {
    setor: Setor;
  };
  isSaving: boolean;
}

const initialState = {
  form: {
    isOpen: false,
    component: '',
  },
  updateForm: {
    setor: {} as Setor,
  },
  isSaving: false,
} as SetorState;

const setorSlice = createSlice({
  name: 'setor',
  initialState,
  reducers: {
    openForm: (state, action) => {
      state.form.isOpen = true;
      state.form.component = action.payload;
    },
    openFormUpdate: (state, action) => {
      state.form.isOpen = true;
      state.form.component = action.payload.component;
      state.updateForm.setor = action.payload.setor;
    },
    closeForm: (state) => {
      if (!state.isSaving) {
        state.form.isOpen = false;
        state.form.component = '';
      }
    },
    saving: (state) => {
      state.isSaving = true;
    },

    saved: (state) => {
      state.isSaving = false;
    },
  },
});
export default setorSlice.reducer;
