import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getHistorico } from '../../services/HistoricoService';

import {
  fimMesISOString,
  inicioMesISOString,
  hojeISOString,
  criarDataISOString,
  hoje,
} from '../../utils/date';
import { Nota } from '../../data/types/Nota';
import { Ocorrencia } from '../../data/types/Ocorrencia';
import { OcorrenciaType } from '../../data/enums/OcorrenciaType';
import { RootState } from '../../state/store';
import { useSelector } from 'react-redux';
import { convertToDate } from '../../utils/conversor';

export const changeUnidadeFilter = createAction<string[]>(
  'dashboard/changeUnidadeFilter'
);

export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetch',
  async (_: void, thunkAPI) => {
    const date = new Date();
    var diaAtual = date.getDate();
    
    var mesAnterior = date.getMonth() - 1;
    
    if (mesAnterior == -1) {
        mesAnterior = 11;
    }

    var historicoServiceDia = await getHistorico(
      hojeISOString(),
      hojeISOString()
    );
    var historicoServiceMesAtual = await getHistorico(
      inicioMesISOString(),
      fimMesISOString()
    );
    
    var historicoServiceMesAnterior = await getHistorico(
      criarDataISOString(1, mesAnterior,2023),
      criarDataISOString(0, mesAnterior,2023)
    );

    var historicoDia = historicoServiceDia;
    
    var historicoMesAtual = historicoServiceMesAtual;
    var historicoMesAnterior = historicoServiceMesAnterior;

    var state: RootState = thunkAPI.getState() as RootState;

  /*  const clientes = state.application.data.clientes;

    const cidades = state.application.data.cidades;

    const rotas = state.application.data.rotas;

    var fretePorTipo: {tipo: string, frete: number}[]= [];

    const ocorrenciaFinalizadora = state.application.data.ocorrencias.filter(
      (ocorrencia) => ocorrencia.SK === OcorrenciaType.FINALIZADORAS.toString()
    )[0] as Ocorrencia;

    //CARD
   
    const finalizadasMesAtual = historicoMesAtual.filter(
      (nota) =>
        ocorrenciaFinalizadora.codigos.includes(nota.codigoOcorrencia) &&
        !['93', '94'].includes(nota.codigoOcorrencia)
    ) as Nota[];*/

    var arrMaioresClientes: { [key: string]: number } = {};
    var arrMaioresDestinatarios: { [key: string]: number } = {};
    var arrFreteRegiao: { [key: string]: number } = {};

    var faturamentoMes = 0;

    var result = historicoMesAtual.reduce(function (r, a) {
      var date = Number.parseInt(a.dataAutorizacao.substring(0, 2));
      r[date] = r[date] || 0;
      r[date] += a.valorFrete;
      faturamentoMes += a.valorFrete;
      return r;
    }, Object.create(null));

    var result2 = historicoMesAnterior.reduce(function (r, a) {
      var date = Number.parseInt(a.dataAutorizacao.substring(0, 2));
      r[date] = r[date] || 0;
      r[date] += a.valorFrete;

      return r;
    }, Object.create(null));

   
    var freteEntrega = 0;
    var freteColeta = 0;
    var freteReentrega = 0;
    var freteDevolucao = 0;
    var freteNFS = 0;
    var freteComplementar = 0;

    historicoMesAtual.map((nota) => {
      var cnpjRaizPagador = nota.cnpjPagadorFrete.substring(0, 8);
      arrMaioresClientes[cnpjRaizPagador] = arrMaioresClientes[cnpjRaizPagador] || 0;
      arrMaioresClientes[cnpjRaizPagador] += nota.valorFrete;
      
       var cnpjRaizDestinatario = nota.cnpjDestinatario.substring(0, 8);
      arrMaioresDestinatarios[cnpjRaizDestinatario] = arrMaioresDestinatarios[cnpjRaizDestinatario] || 0;
      arrMaioresDestinatarios[cnpjRaizDestinatario] += nota.valorFrete;
    });
/*
      var cidade = cidades.filter((cidade) => cidade.nome == nota.cidade);

      var rota = rotas.filter((rota) => rota.nome == cidade[0].rota);
      if (rota.length > 0) {
        arrFreteRegiao[rota[0].nome] = arrFreteRegiao[rota[0].nome] || 0;
        arrFreteRegiao[rota[0].nome] += nota.valorFrete;
      }
     */
/*
      var tipoCte = nota.tipoDocumento;
      
        if (tipoCte == "NORMAL" || tipoCte == "CARGA FECHADA" || tipoCte == "SUBC REC FORM LISO") {
            freteEntrega += nota.valorFrete;
        } else if (tipoCte == "PRES SERVICO") {
            freteNFS += nota.valorFrete;
        } else if (tipoCte == "COMPLEMENTAR FRETE") {
          freteComplementar += nota.valorFrete;
        } else if (tipoCte == "SUBC EXP FORM LISO" || tipoCte == "SUBC FORM CTRC" || tipoCte == "REVERSA") {
          freteColeta += nota.valorFrete;
        } else if (tipoCte == "REENTREGA") {
          freteReentrega += nota.valorFrete;
        } else if (tipoCte == "DEVOLUCAO") {
          freteDevolucao += nota.valorFrete;
        } 
        console.log(tipoCte+": "+nota.numeroNotaFiscal)

    });




      fretePorTipo.push({
        tipo: 'ENTREGA',
        frete: freteEntrega
      });

      fretePorTipo.push({
        tipo: 'COLETA',
        frete: freteColeta
      });
      
      fretePorTipo.push({
        tipo: 'REENTREGA',
        frete: freteReentrega
      });
      
      fretePorTipo.push({
        tipo: 'DEVOLUÇÃO',
        frete: freteDevolucao
      });
      
      fretePorTipo.push({
        tipo: 'COMPLEMENTAR',
        frete: freteComplementar
      });
      
      fretePorTipo.push({
        tipo: 'NFS (DESCARGA, REEMBOLSO)',
        frete: freteNFS
      });      
        
*/
    console.log(arrMaioresDestinatarios);
    
    var ordenarFreteRegiao = Object.entries(arrFreteRegiao).sort(function (
      a,
      b
    ) {
      return a[1] - b[1];
    });
    
    var ordenarFrete = Object.entries(arrMaioresClientes).sort(function (a, b) {
      return a[1] - b[1];
    });

    var ordenarFreteDestinatario = Object.entries(arrMaioresDestinatarios).sort(function (
      a,
      b
    ) {
      return a[1] - b[1];
    });    

    var fretePorRota: {
      rota: string;
      frete: number;
      percentualFaturamento: number;
    }[] = [];
    ordenarFreteRegiao.reverse().forEach((element) => {
      console.log(element);
      var nomeRota = element[0];
      var frete = element[1];
      var percentualFaturamento = (frete / faturamentoMes) * 100;

      fretePorRota.push({
        rota: nomeRota,
        frete: frete,
        percentualFaturamento: percentualFaturamento,
      });
    });

    var maioresClientes: {
      id: number;
      nome: string;
      freteTotal?: number;
      performance: number;
      percentual: number;
    }[] = [];

    var maioresDestinatarios: {
      id: number;
      nome: string;
      freteTotal?: number;
      percentual: number;
    }[] = [];    
/*

    console.log(state.application.data.notas.length)
    const naoFinalizadas = state.application.data.notas.filter(
      (nota) => !ocorrenciaFinalizadora.codigos.includes(nota.codigoOcorrencia)
    ) as Nota[];
*/
    var freteNaoFinalizada = 0;




    var dataHoje = hoje();

    var freteNaoEntregue: {tipo: string, frete: number, mediaDias: number}[]= [];
var cont = 1;
     // console.log(naoFinalizadas)
    /*naoFinalizadas.map((nota) => {
      var dataEmissao = new Date(nota.dataAutorizacaoConverted || 0);

      var difDias =
        (dataHoje.valueOf() - dataEmissao.valueOf()) / (1000 * 60 * 60 * 24);

      var tipoCte = nota.tipoDocumento;
      console.log(nota.tipoDocumento);
      const result = freteNaoEntregue.find(
          (tp) => tp.tipo == tipoCte
        );

        //console.log(result);
        if (result == undefined) {
          freteNaoEntregue.push({
            tipo: tipoCte as string,
            frete: nota.valorFrete,
            mediaDias: 1,
          });
        } else {
          result.frete += nota.valorFrete;
        }

        cont++;
      freteNaoFinalizada += nota.valorFrete;
    });
console.log(cont);
*/

    var qtdNoPrazo = 0;

    var performanceUnidade: {
      unidade: string;
      qtdNfEntregue: number;
      qtdNoPrazo: number;
    }[] = [];

    var noPrazo = false;
    /*
    finalizadasMesAtual.map((nota) => {
      var dataPrevista = convertToDate(nota.dataPrevisaoEntrega);
      var dataEntrega = convertToDate(nota.dataUltimaOcorrencia);

      if (dataEntrega <= dataPrevista) {
        qtdNoPrazo++;
        noPrazo = true;
      } else {
        noPrazo = false;
      }

      var cidade = cidades.filter((cidade) => cidade.nome == nota.cidade);

      var rota = rotas.filter((rota) => rota.nome == cidade[0].rota);
      if (rota.length > 0) {
        const result = performanceUnidade.find(
          (un) => un.unidade == rota[0].unidade
        );

        //console.log(result);
        if (result == undefined) {
          performanceUnidade.push({
            unidade: rota[0].unidade as string,
            qtdNfEntregue: 1,
            qtdNoPrazo: noPrazo ? 1 : 0,
          });
        } else {
          result.qtdNfEntregue += 1;
          if (noPrazo) {
            result.qtdNoPrazo += 1;
          }
        }
      }
    });
*//*
    var performance = (qtdNoPrazo / finalizadasMesAtual.length) * 100;

    var arrHistorico: {
      dia: number;
      freteMesAnterior?: number;
      freteMesAtual?: number;
      mes: string;
    }[] = [];
    var freteMesAtual = 0;
    var freteMesAnterior = 0;
    //  result.sort();
    for (var i = 1; i <= diaAtual; i++) {
      freteMesAtual += result[i] || 0;
      freteMesAnterior += result2[i] || 0;
      const obj = {
        dia: i,
        mes: '',
        freteMesAtual: freteMesAtual,
        freteMesAnterior: freteMesAnterior
        
        
      };
      

      arrHistorico.push(obj);
      
    }
    console.log(arrHistorico);
    for (var j = 1; j <= 10; j++) {
      var cliente = clientes.filter(
        (cliente) =>
          cliente.cnpj.substring(0, 8) ==
          ordenarFrete[ordenarFrete.length - j][0]
      );

      

      var notasPerformanceCliente = finalizadasMesAtual.filter(
        (nota) =>
          nota.cnpjPagadorFrete.substring(0, 8) ==
          ordenarFrete[ordenarFrete.length - j][0]
      );
      var qtdNoPrazo = 0;
      notasPerformanceCliente.map((nota) => {
        var dataPrevista = convertToDate(nota.dataPrevisaoEntrega);
        var dataEntrega = convertToDate(nota.dataUltimaOcorrencia);
        if (dataEntrega <= dataPrevista) {
          qtdNoPrazo++;
        }
      });
      if (cliente[0] === undefined) {
        console.log("NAO ACHOU"+ordenarFrete[ordenarFrete.length - j][0]);
        var nomeCliente = ordenarFrete[ordenarFrete.length - j][0];
        //continue;
       // cliente[0] = ordenarFrete[ordenarFrete.length - j][0];
      } else {
        var nomeCliente = cliente[0].nome;
      }
      
      var frete = ordenarFrete[ordenarFrete.length - j][1];
      var performanceCliente =
        (qtdNoPrazo / notasPerformanceCliente.length) * 100;
      var percentualCliente = (frete / faturamentoMes) * 100;

      maioresClientes.push({
        id: j,
        nome: nomeCliente,
        freteTotal: frete,
        performance: performanceCliente,
        percentual: percentualCliente,
      });
    }


    for (var j = 1; j <= 5; j++) {
      var cliente = clientes.filter(
        (cliente) =>
          cliente.cnpj.substring(0, 8) ==
          ordenarFreteDestinatario[ordenarFreteDestinatario.length - j][0]
      );
 
      
      if (cliente[0] === undefined) {
        var nomeCliente = ordenarFreteDestinatario[ordenarFreteDestinatario.length - j][0];
      } else {
        var nomeCliente = cliente[0].nome;
      }
      
      var frete = ordenarFreteDestinatario[ordenarFreteDestinatario.length - j][1];

      var percentualCliente = (frete / faturamentoMes) * 100;

      maioresDestinatarios.push({
        id: j,
        nome: nomeCliente,
        freteTotal: frete,
        percentual: percentualCliente,
      });
    }    */

    return {
      historicoDia: historicoDia,
      faturamentoMes: faturamentoMes,
      faturamentoMesAnterior: fretePorRota,
      historicoMesAtual: historicoMesAtual,
      notasMesAtual: historicoDia,
      qtdCteMes: historicoDia.length,
      historicoMesAnterior: historicoMesAnterior,
      performance: performance,
      freteNaoEntregue: freteNaoEntregue,
      maioresClientes: maioresClientes,
      maioresDestinatarios: maioresDestinatarios,
      fretePorRota: fretePorRota,
      fretePorTipo: fretePorRota,

      performanceUnidade: performanceUnidade,
    };
  }
);
