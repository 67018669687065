import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import {
  getFrete,
  
} from '../../utils/nota';
import { useDispatch } from 'react-redux';
import { fetchDashboardData } from './dashboardAction';


import React from 'react';

import { formatCurrency, formatNumber } from '../../utils/formatter';
import { getMesPorExtenso, mesAtual } from '../../utils/date';
import { Nota } from '../../data/types/Nota';
import { OcorrenciaType } from '../../data/enums/OcorrenciaType';
import { Ocorrencia } from '../../data/types/Ocorrencia';
import { calculatePesoCubado } from '../../utils/calculate';
import { useNavigate } from 'react-router-dom';

import { Button, Statistic, Card,Typography,Space, Table, Tag,Progress } from 'antd';
import ArrowUpOutlined from '@ant-design/icons/lib/icons/ArrowUpOutlined';
import { AlignType } from 'rc-table/lib/interface';
import { JSX } from 'react/jsx-runtime';
import {DEFAULT_Y_AXIS_KEY, LineChart} from '@mui/x-charts';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import DollarOutlined from '@ant-design/icons/lib/icons/DollarOutlined';
import TrophyFilled from '@ant-design/icons/lib/icons/TrophyFilled';
import Icon, { ArrowDownOutlined } from '@ant-design/icons';
import { VencimentoNota } from '../../data/enums/VencimentoNota';

const { Text, Link } = Typography;

const Dashboard = () => {
  //const dispatch = useDispatch();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  
  /*const ocorrenciaFinalizadora = useSelector(
    (state: RootState) => state.application.data.ocorrencias
  ).filter(
    (ocorrencia) => ocorrencia.SK === OcorrenciaType.FINALIZADORAS.toString()
  )[0] as Ocorrencia;*/

  

  const notas = useSelector((state: RootState) => state.application.data.notas);

  const notasAtrasadas = useSelector(
    (state: RootState) => state.application.data.notas
  ).filter(
    (nota) =>
      nota.vencimento === VencimentoNota.VENCIDA &&
      nota.codigoOcorrencia != 1
  ) as Nota[];  

 
  const notasMesAtual = useSelector((state: RootState) => state.dashboard.notasMesAtual);
  const historicoDia = useSelector((state: RootState) => state.dashboard.historicoDia);
  const historicoMesAtual = useSelector((state: RootState) => state.dashboard.historicoMesAtual);
  const historicoMesAnterior = useSelector((state: RootState) => state.dashboard.historicoMesAnterior);
  const qtdCteMes = useSelector((state: RootState) => state.dashboard.qtdCteMes);
  const faturamentoMes = useSelector((state: RootState) => state.dashboard.faturamentoMes);
  const faturamentoMesAnterior = useSelector((state: RootState) => state.dashboard.faturamentoMesAnterior);
  const variacao = (faturamentoMes / faturamentoMesAnterior - 1) * 100;
  const ticketMedio = faturamentoMes/qtdCteMes;
  const performance = useSelector((state: RootState) => state.dashboard.performance);
  const freteNaoEntregue = useSelector((state: RootState) => state.dashboard.freteNaoEntregue);
  const fretePorTipo = useSelector((state: RootState) => state.dashboard.fretePorTipo);
  const maioresClientes = useSelector((state: RootState) => state.dashboard.maioresClientes);
  const fretePorRota = useSelector((state: RootState) => state.dashboard.fretePorRota);
  const performanceUnidade = useSelector((state: RootState) => state.dashboard.performanceUnidade);
  
  const dashboardState = useSelector((state: RootState) => state.dashboard);

  
  const freteKgMedio = faturamentoMes/calculatePesoCubado(notasMesAtual);  

  var qtdNotasAtrasadas = notasAtrasadas.length;
  //var percentualAtraso = qtdNotasAtrasadas /  

  React.useEffect(() => {
    if(dashboardState.status !== 'success') {
      dispatch(fetchDashboardData());
    }
  }, []);


 
  const unidades = Array.from(
    new Set(notas.map((nota) => nota.unidade))
  ).sort() as string[];



  const keyToLabel: { [key: string]: string } = {
    freteMesAnterior: getMesPorExtenso(mesAtual()-1),
    freteMesAtual: getMesPorExtenso(mesAtual()),
  };
  
  const colors: { [key: string]: string } = {
    freteMesAnterior: 'orange',
    freteMesAtual: 'blue',
    
  };





  const config = {
    percent: 0.75,
    range: {
      ticks: [0, 1 / 3, 2 / 3, 1],
      color: ['#F4664A', '#FAAD14', '#30BF78'],
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    statistic: {
      content: {
        style: {
          fontSize: '36px',
          lineHeight: '36px',
        },
      },
    },
  };   

  const configLiquid = {
    percent: performance/100,
    outline: {
      border: 1,
      distance: 1,
    },
    wave: {
      length: 128,
    },
  };  

  var corPerformance = '#50CF01'; 
  if (performance < 90) {
      corPerformance = '#FF0E43';
  } 
   
  const configRing = {
    height: 200,
    width: 200,
    autoFit: false,
    percent: performance/100,
    color: [corPerformance, '#E8EDF3'],
    statistic: {
      content: {
        style: {
          color: '#363636',
          fontSize: '28px',
          lineHeight: '22px',
          fontweight: '500'

        },
        
      }
    }    
  };  




  const columnsFreteNaoEntregue = [
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      
    },
    {
      title: 'Frete',
      dataIndex: 'frete',
      
      render: (text: number) => formatCurrency(text,2)
    }
  ];
  
  const columsMaioresClientes = [
    { dataIndex: 'id', title: '#'},
    { dataIndex: 'nome', title: 'Cliente', width: '50%' },
    { 
      dataIndex: 'freteTotal', 
      title: 'Frete Total',  
      align: 'right' as AlignType,  
      
      render: (text: number, record: { performance: number; percentual: number; }) => (
          
        <span>
          <Text type="success">{formatCurrency(text,0)}</Text><br/>
             
            <Tag title='PERFORMANCE' color={record.performance < 90 ? 'warning' : 'green'} icon={record.performance < 90 ? <ExclamationCircleOutlined /> : ''}>{formatNumber(record.performance,0)+'%'}</Tag>
            <Tag title='% SOB FATURAMENTO' color="blue">{formatNumber(record.percentual,0)+'%'}</Tag> 
        </span>)
    }           
          
     
  ];  

  var performanceAbaixo: JSX.Element[]  = [];
    performanceUnidade.map((pu) => {
        var performance = pu.qtdNoPrazo / pu.qtdNfEntregue * 100;
        if (performance < 90) {
          performanceAbaixo.push(<Tag color="red">{pu.unidade + " " + formatNumber(performance,0) + "%"}</Tag>);
        }    
    });
  
    var data = fretePorRota;
    const config2 = {
      data,
      
      xField: 'frete',
      yField: 'rota',
      seriesField: 'rota',

      
    };   

    const chartSetting = {
      xAxis: [
        {
          label: 'rainfall (mm)',
        },
      ],
      width: 500,
      height: 400,
    };   

    const configLine = {
      data: historicoMesAtual,
      xField: 'dia',
      yField: 'frete',
      seriesField: 'mes',
      
      color: ['#1979C9', '#D62A0D'],
    };    

  if(dashboardState.status === 'success') {

  
  return (
    <div className='w-full h-full justify-center p-5 grid grid-rows-[80px, auto]'>

     <Space size="large">
      <div className='grid grid-cols-3 gap-5 pt-5 overflow-auto'>
       {/*} 

        
        

        
        <Card title={'Total CTEs emitidos ' + getMesPorExtenso(mesAtual())} icon={<IoDocumentOutline />}>

      </Card>   

        <Card title={'Performance ' + getMesPorExtenso(mesAtual())} icon={<IoDocumentOutline />}>
           
          {formatNumber(qtdCteMes, 0)}<br/>
          
                          
        </Card>

        <Card title='Frete KG MÉDIO' icon={<LiaWeightHangingSolid />}>
         {formatCurrency(freteKgMedio, 2)}/KG
        </Card>
        
        <Card title='MAIORES CLIENTES'  icon={<LiaWeightHangingSolid />}>

        
     
          {formatCurrency(ticketMedio, 2)}
          {formatNumber(ticketMediokG, 2)} KG         
    
    </Card>
    

             
          */} 
<Card title="FATURAMENTO" style={{width:250}} headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}>
      <p><Statistic title="Faturamento do dia " value={getFrete(historicoDia, 2)} precision={2} /></p>
      <p><Statistic title={"Faturamento de " + getMesPorExtenso(mesAtual())} value={formatCurrency(faturamentoMes, 2)} precision={2} /></p>
       
      <Statistic
          title="Comparação mês anterior"
          value={variacao}
          precision={2}
          valueStyle={variacao>0 ? { color: '#3f8600' } : { color: 'red' }}
          prefix={variacao>0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          suffix="%"
        />      
    </Card>  
<Card title={<Icon type="message" style={{ fontSize: '16px', color: '#08c' }}  />} style={{width:250,textAlign:"center"}} headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}>
   <p>&nbsp;</p>
  <Progress {...configRing} />
  <br/>
  {performanceAbaixo}
  <br/><br/>         
  <Button danger onClick={() => navigate('/atrasadas')}>Ver atrasados ({qtdNotasAtrasadas})</Button>
</Card>  

<Card title="FRETE"  style={{ width: 500 }} headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}>
  <Table dataSource={fretePorTipo} columns={columnsFreteNaoEntregue} pagination={false} />
</Card>    

    <Card title="TOP 5 CLIENTES" style={{ width: 450 }} >
    <Table dataSource={maioresClientes} columns={columsMaioresClientes} pagination={false} />
    </Card>   

    <Card title="TOP 5 CLIENTES"  >
     
    </Card>
    
    
    <Card title="LINHA DO TEMPO FATURAMENTO">
    

    </Card> 
        {/*
        <Gauge {...config} />

        <Liquid {...configLiquid} />

       */}
        
     </div> 
     </Space>
    </div>
  );
  }
};
export default Dashboard;
