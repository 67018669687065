import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaginaAtrasadas } from '../../../services/PaginaService';

export const fetchAtrasadasData = createAsyncThunk(
  'atrasadas/fetch',
  async () => {
    var notasCarregamento = await getPaginaAtrasadas();
    return notasCarregamento;
  }
);
