import {
  Button,
  Drawer,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  tableCellClasses,
} from '@mui/material';
import { AppDispatch, RootState } from '../../state/store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { closeNotaTab, createAcompanhamento } from './state/notaAction';
import Header from './components/Header';

import React, { useEffect, useState } from 'react';
import { fetchNotaData } from './state/notaFetch';
import {
  formatCurrency,
  formatDataHora,
  formatDate,
  formatPeso,
} from '../../utils/formatter';
import Box from '../../components/Box';
import { Ocorrencia } from '../../data/types/Ocorrencia';
import { Acompanhamento } from '../../data/types/Acompanhamento';
import StatusNota from '../../components/StatusNota';

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = ({ value, index, children }: TabPanelProps) => {
  return (
    <div
      hidden={value !== index}
      className='h-full bg-[#f7f9fc]  overflow-hidden'
    >
      {value === index && (
        <React.Fragment>
          <div className='p-5 h-full w-full  overflow-hidden'>
            <div className='h-full w-full overflow-auto'>{children}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const Nota = () => {
  const dispatch: AppDispatch = useDispatch();

  const [value, setValue] = useState(0);

  const [descricao, setDescricao] = useState('');

  const isNotaOpen = useSelector((state: RootState) => state.nota.isOpen);
  const nota = useSelector((state: RootState) => state.nota.data.nota);
  const isMobile = useSelector(
    (state: RootState) => state.application.isMobile
  );
  useEffect(() => {
    if (nota.ctrc !== undefined) {
      dispatch(fetchNotaData(nota.idNota));
    }
  }, [nota]);
  const ocorrencias = useSelector(
    (state: RootState) => state.nota.data.ocorrencias
  ) as Ocorrencia[];

  const acompanhamentos = useSelector(
    (state: RootState) => state.nota.data.acompanhamentos
  ) as Acompanhamento[];

  if (nota.ctrc === undefined || ocorrencias.length === 0) {
    return null;
  }

  const handleCadastrarAcompanhamento = () => {
    const acompanhamento = { descricao, idNota: nota.idNota } as Acompanhamento;
    dispatch(createAcompanhamento(acompanhamento)).then(() => {
      setDescricao('');
    });
  };

  return (
    <Drawer
      key={nota.idNota}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : '90%',
        },
      }}
      variant='temporary'
      open={isNotaOpen}
      onClose={() => dispatch(closeNotaTab())}
    >
      <div className='grid grid-rows-[112px_auto] h-full'>
        <div>
          <Header nota={nota} />
          {/* <Box> */}
          <Tabs
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
          >
            <Tab label='Informações' />
            <Tab label='Acompanhamento' />
          </Tabs>
          {/* </Box> */}
        </div>

        <div className='overflow-auto'>
          <TabPanel value={value} index={0}>
            <React.Suspense fallback={<div>Loading</div>}>
              <div className='grid grid-cols-12 grid-rows-6 h-full gap-5 text-md'>
                <Box className='col-span-6 row-span-6'>
                  <TableContainer>
                    <Table
                      className='w-[200px]'
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: 'none',
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>Status:</span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md uppercase'>
                            {nota.status}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>
                            Em Trânsito:
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md uppercase'>
                            {nota.transito ? 'Sim' : 'Não'}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>
                            Nota Fiscal:
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md'>
                            {nota.numeroNotaFiscal}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>CTRC:</span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md'>{nota.ctrc}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>
                            Peso Real / Cubado:
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md'>
                            {formatPeso(nota.pesoReal, 2)} /{' '}
                            {formatPeso(nota.pesoCubado, 2)}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>
                            Valor do Frete:
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md'>
                            {formatCurrency(nota.valorFrete, 2)}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>
                            Valor da Mercadoria:
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md'>
                            {formatCurrency(nota.valorMercadoria, 2)}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>
                            Data Emissão:
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md'>
                            {formatDate(nota.dataAutorizacao)}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>
                            Data Limite:
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md'>
                            <StatusNota nota={nota} />
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>Remetente:</span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md'>{nota.remetente}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>
                            Destinatário:
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md'>{nota.destinatario}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>Cidade:</span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md'>{nota.cidade}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>Romaneio:</span>
                        </TableCell>
                        <TableCell>
                          <span className='text-md'>{nota.codigoRomaneio}</span>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Box>
                <Box className='col-span-6 row-span-6 overflow-y-scroll'>
                  <TableContainer>
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: 'none',
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          <span className='font-bold'>Ocorrência</span>
                        </TableCell>
                        <TableCell>
                          <span className='font-bold'>Data</span>
                        </TableCell>
                        <TableCell>
                          <span className='font-bold'>Descrição</span>
                        </TableCell>
                      </TableRow>
                      {ocorrencias.map((ocorrencia, index) => (
                        <TableRow key={index}>
                          <TableCell>{ocorrencia.codigo}</TableCell>
                          <TableCell>{formatDate(ocorrencia.data)}</TableCell>
                          <TableCell>{ocorrencia.descricao}</TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </TableContainer>
                </Box>
                {/* <div className='col-span-4 row-span-4 bg-white shadow-md'></div> */}
                {/* <Box className='col-span-12 row-span-0 overflow-scroll'> */}
                {/* teset */}
                {/* </Box> */}
              </div>
            </React.Suspense>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <React.Suspense fallback={<div>Loading</div>}>
              <div className='grid grid-cols-12 grid-rows-[210px_auto] h-full gap-5 text-md'>
                <Box className='col-span-12'>
                  <div className='p-5'>
                    <TextField
                      multiline
                      label='Descrição'
                      id='descricao'
                      name='descricao'
                      value={descricao}
                      rows={3}
                      fullWidth
                      onChange={(e) => setDescricao(e.target.value)}
                    />
                  </div>
                  <div className='float-right p-5'>
                    <Button
                      onClick={() => handleCadastrarAcompanhamento()}
                      variant='contained'
                      color='primary'
                    >
                      Cadastrar
                    </Button>
                  </div>
                </Box>
                <Box className='col-span-12'>
                  <TableContainer sx={{ maxHeight: '100%' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell width={'200px'}>Data</TableCell>
                          <TableCell width={'300px'}>Usuário</TableCell>
                          <TableCell>Descrição</TableCell>
                        </TableRow>
                      </TableHead>
                      {acompanhamentos.map((acompanhamento, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {formatDataHora(acompanhamento.data)}
                          </TableCell>
                          <TableCell>{acompanhamento.email}</TableCell>
                          <TableCell>{acompanhamento.descricao}</TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </TableContainer>
                </Box>
              </div>
            </React.Suspense>
          </TabPanel>
        </div>
      </div>
    </Drawer>
  );
};
export default Nota;
