import StatusNota from '../../components/StatusNota';
import { formatDataHora, formatDate } from '../../utils/formatter';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Nota } from '../../data/types/Nota';
import NumeroNotaFiscal from '../../components/NumeroNotaFiscal';
import { Button, TextField } from '@mui/material';
import { useRef } from 'react';
import { NotaPerformance } from '../../data/types/NotaPerformance';

export const columns = [
  {
    field: 'numeroNotaFiscal',
    headerName: 'NF',
    flex: 0.7,
    renderCell: ({ row }: GridRenderCellParams<Nota>) => {
      return <NumeroNotaFiscal nota={row} />;
    },
  },
  {
    field: 'remetente',
    headerName: 'Remetente',
    flex: 2,
  },
  {
    field: 'destinatario',
    headerName: 'Destinatario',
    flex: 2,
  },
  {
    field: 'cidade',
    headerName: 'Cidade',
    flex: 1.5,
  },
  {
    field: 'dataAutorizacao',
    headerName: 'Emissão',
    flex: 1,
    valueGetter: (params: GridRenderCellParams) => {
      return formatDate(params.row.dataAutorizacao);
    },
  },
  {
    field: 'dataChegadaDestino',
    headerName: 'Chegada Parceiro',
    flex: 1.3,
    valueGetter: (params: GridRenderCellParams) => {
      return formatDate(params.row.dataChegadaDestino);
    },
  },
  {
    field: 'limiteParceiro',
    headerName: 'Prazo Parceiro',
    flex: 1,
    valueGetter: (params: GridRenderCellParams) => {
      return formatDate(params.row.dataLimiteParceiro);
    },
  },
  {
    field: 'dataPrevisaoEntrega',
    headerName: 'Data Limite CTE',
    flex: 1,
    valueGetter: (params: GridRenderCellParams) => {
      return formatDate(params.row.dataLimite);
    },
  },
  {
    field: 'dataNotaFinalizada',
    headerName: 'Data Entrega',
    flex: 1,
    valueGetter: (params: GridRenderCellParams) => {
      return formatDate(params.row.dataEntrega);
    },
  },

  {
    field: 'statusEntrega',
    headerName: 'Status',
    flex: 1.5,
    renderCell: ({ row }: GridRenderCellParams) => {
      if (!row.atrasada) {
        return (
          <div className={`bg-green-400 px-2 rounded-full text-white`}>
            Entregue no Prazo
          </div>
        );
      } else {
        if (row.responsabilidadeMatriz) {
          return (
            <div className={`bg-red-400 px-2 rounded-full text-white`}>
              Atraso Transportadora
            </div>
          );
        } else {
          return (
            <div className={`bg-orange-400 px-2 rounded-full text-white`}>
              Atraso Parceiro
            </div>
          );
        }
      }
    },
  },
] as GridColDef[];
