import { BiArrowBack } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { closeParameter } from '../../../state/application/applicationAction';
import { closeNotaTab } from '../state/notaAction';
import { Nota } from '../../../data/types/Nota';
import { IoMdCloseCircle } from 'react-icons/io';

type HeaderProps = {
  nota: Nota;
};

const Header = ({ nota }: HeaderProps) => {
  const dispatch = useDispatch();

  return (
    <div className='w-full h-[60px] bg-primary-600 flex text-white items-center p-5'>
      <div className='flex items-center'>
        <span
          onClick={() => dispatch(closeNotaTab())}
          className='pl-5 cursor-pointer'
        >
          <BiArrowBack />
        </span>
        <span className='pl-5'>Nota {nota.numeroNotaFiscal}</span>
      </div>
      <div
        onClick={() => dispatch(closeNotaTab())}
        className='cursor-pointer ml-auto flex items-center gap-1'
      >
        <div className='text-[12px] font-thin'>FECHAR</div>
        <IoMdCloseCircle className='text-2xl' />
      </div>
    </div>
  );
};
export default Header;
