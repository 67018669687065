import { Setor } from '../data/types/Setor';
import { deleteRequest, getRequest, postRequest } from './service';

export const getSetores = async (): Promise<Setor[]> => {
  return await getRequest<Setor[]>('/setor');
};

export const updateSetor = async (
  idSetor: number,
  nome: string
): Promise<any> => {
  return await postRequest<{ nome: string }>(`/setor/${idSetor}`, { nome });
};

export const createSetor = async (nome: string): Promise<any> => {
  return await postRequest<{ nome: string }>('/setor', { nome });
};

export const deleteSetor = async (idSetor: number): Promise<Setor> => {
  return await deleteRequest<Setor>(`/setor/${idSetor}`);
};
