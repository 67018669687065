import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCidades } from '../../../services/CidadeService';
import { getRotas } from '../../../services/RotaService';
import { getClientes } from '../../../services/ClienteService';
import { getUsuarios } from '../../../services/UsuarioService';
import { getParametros } from '../../../services/ParametroService';
import { getSetores } from '../../../services/SetorService';
import { getUnidades } from '../../../services/UnidadeService';
import { RootState } from '../../../state/store';
import { closeParameter } from '../../../state/application/applicationAction';

export const fetchParameterData = createAsyncThunk(
  'parameter/fetch',
  async (_, thunkApi) => {
    const cidades = await getCidades();
    const rotas = await getRotas();
    const clientes = await getClientes();
    const usuarios = await getUsuarios();
    const parametros = await getParametros();
    const setores = await getSetores();
    const unidades = await getUnidades();

    const autoCloseParameter = (thunkApi.getState() as RootState).application
      .autoCloseParameter;
    if (autoCloseParameter) {
      thunkApi.dispatch(closeParameter());
    }

    return {
      cidades,
      clientes,
      rotas,
      parametros,
      usuarios,
      setores,
      unidades,
    };
  }
);
