import { VencimentoNota } from '../data/enums/VencimentoNota';
import { Nota } from '../data/types/Nota';
import { formatDate } from '../utils/formatter';

type StatusNotaProps = {
  nota: Nota;
};

const StatusNota = (props: StatusNotaProps) => {
  const nota = props.nota;

  const dataPrevisaoEntrega = nota.dataPrevisaoEntrega;
  const data = nota.dataAgendamento
    ? nota.dataAgendamento
    : dataPrevisaoEntrega;

  if (
    nota.dataAgendamento !== null &&
    nota.vencimento !== VencimentoNota.VENCIDA
  ) {
    return (
      <span>
        <span
          className={'text-bold rounded-xl bg-agendado text-white p-1 px-2'}
        >
          {formatDate(data)}
        </span>
      </span>
    );
  }

  if (nota.vencimento === VencimentoNota.VENCIDA) {
    return (
      <span>
        <span className={'text-bold rounded-xl bg-vencido text-white p-1 px-2'}>
          {formatDate(data)}
        </span>
      </span>
    );
  } else {
    if (nota.vencimento === VencimentoNota.HOJE) {
      return (
        <span>
          <span className={'text-bold rounded-xl bg-hoje p-1 px-2'}>
            {formatDate(data)}
          </span>
        </span>
      );
    } else {
      if (nota.vencimento === VencimentoNota.A_VENCER) {
        return (
          <span>
            <span className={'text-bold rounded-xl bg-proximo p-1 px-2'}>
              {formatDate(data)}
            </span>
          </span>
        );
      } else {
        return (
          <span>
            <span>{formatDate(data)}</span>
          </span>
        );
      }
    }
  }
};

export default StatusNota;
