import { Cidade } from '../data/types/Cidade';
import { getRequest, postRequest } from './service';

export const getCidades = async (): Promise<Cidade[]> => {
  return await getRequest<Cidade[]>('/cidade');
};

export const postCidade = async (
  idCidade: number,
  idRota: number,
  idSetor: number,
  prazoEntrega: number
) => {
  return await postRequest<{
    idRota: number;
    idSetor: number;
    prazoEntrega: number;
  }>(`/cidade/${idCidade}`, { idRota, idSetor, prazoEntrega });
};
