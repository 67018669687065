import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Main from './templates/Main/Main';
import Carregamento from './pages/carregamento/Carregamento';
import BuildCarregamento from './pages/buildCarregamento/BuildCarregamento';
import Dashboard from './pages/dashboard/Dashboard';
import Atrasadas from './pages/atrasadas/Atrasadas';
import Canhotos from './pages/canhotos/Canhotos';
import Monitorados from './pages/monitorados/Monitorados';
import Parameter from './pages/parameter/Parameter';
import { fetchApplicationData } from './state/application/applicationFetch';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './state/store';
import CustomizedSnackbar from './components/CustomizedSnackbar';
import Agendamento from './pages/agendamento/Agendamento';
import Nota from './pages/nota/Nota';
import { useSelector } from 'react-redux';
import { isMobile, isWeb } from './state/application/applicationAction';
import Agendadas from './pages/agendadas/Agendadas';
import Pendencia from './pages/pendencia/Pendencia';
import Entregas from './pages/entregas/Entregas';
import Parceiros from './pages/parceiros/Parceiros';

function App() {
  const dispatch: AppDispatch = useDispatch();

  const handleResize = () => {
    if (window.innerWidth <= 960) {
      dispatch(isMobile());
    } else {
      dispatch(isWeb());
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Main />}>
          <Route path='/' element={<Navigate to='/carregamento' replace />} />
          <Route path='/carregamento' element={<Carregamento />} />
          <Route
            path='/selecionarCarregamento'
            element={<BuildCarregamento />}
          />
          <Route path='/atrasadas' element={<Atrasadas />} />
          <Route path='/canhotos' element={<Canhotos />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/monitorados' element={<Monitorados />} />
          <Route path='/agendamento' element={<Agendamento />} />
          <Route path='/agendados' element={<Agendadas />} />
          <Route path='/pendencia' element={<Pendencia />} />
          <Route path='/entregas' element={<Entregas />} />
          <Route path='/parceiros' element={<Parceiros />} />
        </Route>
      </Routes>
      <Nota />
      <Parameter />
      <CustomizedSnackbar />
    </BrowserRouter>
  );
}
export default App;
