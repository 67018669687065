import { createSlice } from '@reduxjs/toolkit';
import { Nota } from '../../../data/types/Nota';
import { fetchNotaData } from './notaFetch';
import { Ocorrencia } from '../../../data/types/Ocorrencia';
import { Acompanhamento } from '../../../data/types/Acompanhamento';

export interface NotaState {
  isOpen: boolean;
  data: {
    nota: Nota;
    ocorrencias: Ocorrencia[];
    acompanhamentos: Acompanhamento[];
  };
}

const initialState = {
  isOpen: false,
  data: {
    nota: {},
    ocorrencias: [] as Ocorrencia[],
    acompanhamentos: [] as Acompanhamento[],
  },
} as NotaState;

const notaSlice = createSlice({
  name: 'nota',
  initialState,
  reducers: {
    openNotaTab: (state, action) => {
      state.isOpen = true;
      state.data.nota = action.payload;
    },
    closeNotaTab: (state) => {
      state.isOpen = false;
      state.data.nota = {} as Nota;
      state.data.ocorrencias = [];
      state.data.acompanhamentos = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotaData.pending.type, (state) => {});
    builder.addCase(fetchNotaData.fulfilled, (state, action) => {
      state.data.ocorrencias = action.payload.ocorrencias;
      state.data.acompanhamentos = action.payload.acompanhamentos;
    });
  },
});
export default notaSlice.reducer;
