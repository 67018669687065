import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaginaAgendadas } from '../../../services/PaginaService';

type FetchAgendadasProps = {
  inicio: string;
  fim: string;
};

export const fetchNotasAgendadas = createAsyncThunk(
  'agendadas/fetch',
  async (props: FetchAgendadasProps) => {
    var notasAgendadas = await getPaginaAgendadas(props.inicio, props.fim);
    return notasAgendadas;
  }
);
