import { Rota } from '../data/types/Rota';
import { deleteRequest, getRequest, postRequest } from './service';

export const getRotas = async (): Promise<Rota[]> => {
  return await getRequest<Rota[]>('/rota');
};

export const updateRota = async (
  idRota: number,
  nome: string,
  idUnidade: number
) => {
  return await postRequest<any>(`/rota/${idRota}`, {
    nome: nome,
    idUnidade: idUnidade,
  });
};

export const deleteRota = async (idRota: number) => {
  return await deleteRequest<Rota>(`/rota/${idRota}`);
};

export const createRota = async (nome: string, idUnidade: number) => {
  return await postRequest<any>('/rota', { nome: nome, idUnidade: idUnidade });
};
