import { Button, InputAdornment, OutlinedInput } from '@mui/material';
import {
  DataGrid,
  GridPagination,
  GridRowIdGetter,
  ptBR,
} from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../../state/store';
import { useSelector } from 'react-redux';
import { columns } from './columns';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { closeForm, openForm } from './state/unidadeAction';
import FormUpdateUnidade from './FormUpdateUnidade';
import FormCreateUnidade from './FormCreateUnidade';

const CustomPagination = ({ paginationProps }: any) => {
  const dispatch = useDispatch();
  return (
    <div className='flex items-center'>
      <div style={{ flexGrow: 1 }}>
        <GridPagination {...paginationProps} />
      </div>
      <div className='px-2'>
        <Button
          onClick={() => dispatch(openForm('create'))}
          variant='contained'
        >
          Adicionar Unidade
        </Button>
      </div>
    </div>
  );
};

function CustomToolbar(setBusca: Function, value: string) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBusca(e.target.value);
  };

  return (
    <div className='p-5'>
      <OutlinedInput
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
        placeholder='Pesquisar'
        fullWidth
        startAdornment={
          <InputAdornment position='start'>
            <BiSearch fontSize='large' />
          </InputAdornment>
        }
      />
    </div>
  );
}

const Unidade = () => {
  const dispatch: AppDispatch = useDispatch();
  const [busca, setBusca] = useState('');

  const isOpen = useSelector((state: RootState) => state.unidade.form.isOpen);

  const component = useSelector(
    (state: RootState) => state.unidade.form.component
  );

  const unidades = useSelector(
    (state: RootState) => state.parameter.data.unidades
  ).filter(
    (unidade) => unidade.nome.toUpperCase().indexOf(busca.toUpperCase()) !== -1
  );

  return (
    <div className='h-full p-5'>
      <div
        className={
          isOpen
            ? 'h-full grid grid-cols-[60%_40%] gap-5 transition-all duration-900 overflow-hidden'
            : 'h-full grid grid-cols-[100%_0px] gap-5 transition-all duration-900 overflow-hidden'
        }
      >
        <div className='h-full overflow-hidden shadow-md'>
          <DataGrid
            sx={{ backgroundColor: 'white' }}
            slots={{
              toolbar: () => CustomToolbar(setBusca, busca),
              pagination: CustomPagination,
            }}
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rowHeight={40}
            initialState={{
              sorting: {
                sortModel: [{ field: 'nome', sort: 'asc' }],
              },
            }}
            rows={unidades}
            getRowId={(unidade) => unidade.idUnidade}
            columns={columns(dispatch)}
            onCellKeyDown={(params, events) => events.stopPropagation()}
          />
        </div>
        <div className='bg-white shadow-md flex w-full flex-col'>
          {isOpen && component === 'update' && (
            <div>{<FormUpdateUnidade />}</div>
          )}
          {isOpen && component === 'create' && (
            <div>{<FormCreateUnidade />}</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Unidade;
