import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCte from './TableCte';
import { withStyles, createStyles } from '@material-ui/styles';
import { Nota } from '../../../data/types/Nota';
import { getFrete, getPesoCubado, getPesoReal } from '../../../utils/nota';

const StyledTableCell = withStyles(() =>
  createStyles({
    root: {
      whiteSpace: 'nowrap',
    },
    body: {
      fontSize: 18,
    },
  })
)(TableCell);

type SelectProps = {
  notas: Nota[];
};

const Select = (props: SelectProps) => {
  const notas = props.notas;

  const setorSet = new Set(notas.map((nota) => nota.setor));
  const setores = Array.from(setorSet) as string[];

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width='5%'></TableCell>
              <StyledTableCell width='20%'>
                <h5>CIDADE</h5>
              </StyledTableCell>
              <TableCell align='center'></TableCell>
              <StyledTableCell width='15%'>
                <h5>PESO REAL</h5>
              </StyledTableCell>
              <StyledTableCell width='15%'>
                <h5>PESO CUBADO</h5>
              </StyledTableCell>
              <StyledTableCell width='5%'>
                <h5>FRETE</h5>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {setores.map((setor, index) => {
              const notasSetor = notas.filter((nota) => nota.setor === setor);

              const cidadeSet = new Set(notasSetor.map((nota) => nota.cidade));
              const cidades = Array.from(cidadeSet).sort((a, b) => {
                if (a > b) return 1;
                if (a < b) return -1;
                return 0;
              }) as string[];

              return (
                <React.Fragment key={index}>
                  <TableRow
                    sx={{
                      fontSize: 10,
                      backgroundColor: '#7BAEFF',
                      display: setor === null ? 'none' : 'visible',
                    }}
                  >
                    <TableCell></TableCell>
                    <TableCell>[{setor}]</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{getPesoReal(notasSetor, 0)}</TableCell>
                    <TableCell>{getPesoCubado(notasSetor, 0)}</TableCell>
                    <TableCell
                      sx={{
                        color: 'green',
                        textAlign: 'right',
                      }}
                    >
                      {getFrete(notasSetor, 2)}
                    </TableCell>
                  </TableRow>

                  {cidades.map((cidade, index) => {
                    const notasCidade = notas.filter(
                      (nota) => nota.cidade === cidade
                    );
                    return (
                      <TableCte
                        key={index}
                        notas={notasCidade}
                        cidade={cidade}
                      />
                    );
                  })}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default Select;
