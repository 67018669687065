import { createSlice, current } from '@reduxjs/toolkit';
import { Nota } from '../../data/types/Nota';
import { Rota } from '../../data/types/Rota';

export interface BuildCarregamentoState {
  unidade: string;
  rota: Rota;
  notas: Nota[];
  ordemCarregamento: string[];
}
const initialState = {
  unidade: '',
  rota: {} as Rota,
  notas: [] as Nota[],
  ordemCarregamento: [] as string[],
} as BuildCarregamentoState;

const buildCarregamentoSlice = createSlice({
  name: 'carregamento',
  initialState,
  reducers: {
    openCarretamento: (state, action) => {
      state.rota = action.payload.rota;
      state.unidade = action.payload.unidade;
      state.ordemCarregamento = initialState.ordemCarregamento;
      state.notas = initialState.notas;
    },
    closeCarregamento: (state) => {
      state = initialState;
    },
    selectNota: (state, action) => {
      var carregamentoTemp = Array.from(current(state.notas)) as Nota[];
      var ordemCarregamentoTemp = Array.from(
        current(state.ordemCarregamento)
      ) as string[];
      const nota = action.payload.nota;
      if (action.payload.select) {
        carregamentoTemp.push(nota);
        if (!ordemCarregamentoTemp.includes(nota.cidade)) {
          ordemCarregamentoTemp.push(nota.cidade);
        }
      } else {
        carregamentoTemp.splice(carregamentoTemp.indexOf(nota), 1);
        if (
          carregamentoTemp.filter((c) => c.cidade == nota.cidade).length == 0
        ) {
          ordemCarregamentoTemp.splice(
            ordemCarregamentoTemp.indexOf(nota.cidade),
            1
          );
        }
      }
      state.notas = carregamentoTemp;
      state.ordemCarregamento = ordemCarregamentoTemp;
    },
    changeOrdemCarregamento: (state, action) => {
      var ordemCarregamentoTemp = Array.from(
        current(state.ordemCarregamento)
      ) as string[];
      const [reorderedItem] = ordemCarregamentoTemp.splice(
        action.payload.index,
        1
      );
      ordemCarregamentoTemp.splice(
        action.payload.destination,
        0,
        reorderedItem
      );
      state.ordemCarregamento = ordemCarregamentoTemp;
    },
    selectAll: (state, action) => {
      const select = action.payload.select;
      let cidade = action.payload.cidade as string;
      var notasCidade = action.payload.notas as Nota[];
      var carregamentoTemp = Array.from(current(state.notas)) as Nota[];
      var ordemCarregamentoTemp = Array.from(
        current(state.ordemCarregamento)
      ) as string[];
      notasCidade.forEach((nota: Nota) => {
        const index = carregamentoTemp.indexOf(nota);
        if (index > -1) {
          carregamentoTemp.splice(index, 1);
        }
      });
      const index = ordemCarregamentoTemp.indexOf(cidade);
      if (select) {
        carregamentoTemp = carregamentoTemp.concat(notasCidade);
        if (index === -1) {
          ordemCarregamentoTemp.push(cidade);
        }
      } else {
        if (index > -1) {
          ordemCarregamentoTemp.splice(index, 1);
        }
      }
      state.ordemCarregamento = ordemCarregamentoTemp;
      state.notas = carregamentoTemp;
    },
    clearCarregamento: (state) => {
      state.ordemCarregamento = initialState.ordemCarregamento;
      state.notas = initialState.notas;
    },
  },
});
export default buildCarregamentoSlice.reducer;
