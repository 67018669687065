import { useState } from 'react';
import CardEntrega from './CardEntrega';
import { Entrega } from '../../../data/types/Entrega';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../state/store';
import Loading from '../../../components/Loading';
import { useDispatch } from 'react-redux';
import { registrarEntrega } from '../state/entregasFetch';

type EntretaProps = {
  entregas: Entrega[];
};

const PainelEntregas = ({ entregas }: EntretaProps) => {
  const dispatch: AppDispatch = useDispatch();

  const registrar = (idEntrega: number, status: string, observacao: string) => {
    dispatch(registrarEntrega({ idEntrega, status, observacao }));
  };

  const isLoading = useSelector((state: RootState) => state.entregas.isLoading);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className='grid h-full md:grid-cols-entrega overflow-auto gap-4 content-start justify-center'>
      {entregas.map((entrega, index) => {
        return (
          <CardEntrega key={index} entrega={entrega} registrar={registrar} />
        );
      })}
    </div>
  );
};
export default PainelEntregas;
