import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaginaParceiros } from '../../../services/PaginaService';

export const fetchParceirosData = createAsyncThunk(
  'parceiros/fetch',
  async () => {
    const inicio = '2024-10-01';
    const fim = '2024-10-31';
    const parceiros = await getPaginaParceiros(inicio, fim);
    return parceiros;
  }
);
