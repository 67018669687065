import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaginaMonitorados } from '../../../services/PaginaService';

export const fetchMonitoradosData = createAsyncThunk(
  'monitorados/fetch',
  async () => {
    var notasCarregamento = await getPaginaMonitorados();
    return notasCarregamento;
  }
);
