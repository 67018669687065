import { getRequest, postRequest } from './service';

export const getAcompanhamento = async (idNota: number) => {
  return await getRequest<any[]>(`/nota/${idNota}/acompanhamento`);
};

export const createAcompanhamento = async (
  idNota: number,
  descricao: string
) => {
  const data = new Date(Date.now() - 3 * 60 * 60000);
  return await postRequest<any>(`/nota/${idNota}/acompanhamento`, {
    data,
    descricao,
  });
};
