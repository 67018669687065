import CircularProgress from '@mui/material/CircularProgress';
type LoadingProps = {
  className?: string; // className é opcional
};

const Loading = ({ className = '' }: LoadingProps) => {
  return (
    <div
      className={`${className} w-full h-full flex items-center justify-center`}
    >
      <CircularProgress />
    </div>
  );
};
export default Loading;
