import Tooltip from '@mui/material/Tooltip';

type NotaFiscalProps = {
  numeroNotaFiscal: string;
};

const NotaFiscal = (props: NotaFiscalProps) => {
  const numeroNotaFiscal = props.numeroNotaFiscal.match(/(?<=\/)\d*/g);
 // const numeroNotaFiscalList = numeroNotaFiscal;

  if (numeroNotaFiscal != null && numeroNotaFiscal.length > 1) {
    return (
      <Tooltip title={numeroNotaFiscal.join(', ')}>
        <div>
          {numeroNotaFiscal[0]}
          ...
        </div>
      </Tooltip>
    );
  } else {
    return <div>{numeroNotaFiscal}</div>;
  }
};
export default NotaFiscal;
