import { createSlice, current } from '@reduxjs/toolkit';
import { fetchEntregasData } from './entregasFetch';
import { Nota } from '../../../data/types/Nota';
import { Cliente } from '../../../data/types/Cliente';
import { Entrega } from '../../../data/types/Entrega';

export interface EntregasState {
  isLoading: boolean;
  isOpenPanel: boolean;
  data: {
    entregas: Entrega[];
  };
}
const initialState = {
  isLoading: false,
  isOpenPanel: false,
  data: {
    entregas: [],
  },
} as EntregasState;

const entregasSlice = createSlice({
  name: 'entregas',
  initialState,
  reducers: {
    openPanel: (state) => {
      state.isOpenPanel = true;
    },
    closePanel: (state) => {
      state.isOpenPanel = false;
    },
    registrarEntrega: (state, action) => {
      const entrega = state.data.entregas.find(
        (entrega) => entrega.idEntrega === action.payload.idEntrega
      );
      if (entrega) {
        entrega.status = action.payload.status;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEntregasData.pending.type, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEntregasData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.entregas = action.payload;
    });
  },
});
export default entregasSlice.reducer;
