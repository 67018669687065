import React from 'react';
import { ACCESS_TOKEN } from '../data/constants/accessToken';
import jwt from 'jwt-decode';

type JWTToken = {
  exp: number;
};

const Authenticator = ({ children }: { children: React.ReactNode }) => {
  const appUrl = process.env.REACT_APP_URL as string;
  var accessToken = localStorage.getItem(ACCESS_TOKEN) as string;
  if (accessToken === null) {
    let url = window.location.href;
    if (!url.includes('access_token')) {
      const cognitoUrl = process.env.REACT_APP_COGNITO_URL as string;
      window.location.href = cognitoUrl + appUrl;
    } else {
      accessToken = getAccessTokenFromUrl(url);
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      window.location.href = appUrl;
    }
  }

  const decodedToken = jwt(accessToken) as JWTToken;
  const currentDate = new Date();
  const timestamp = currentDate.getTime();
  const exp = decodedToken['exp'];

  const deslogar = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    window.location.reload();
  };
  const millisecontsToExpire =
    (exp - parseInt(timestamp.toString().substr(0, 10))) * 1000;
  setTimeout(deslogar, millisecontsToExpire);

  return <>{children}</>;
};
export default Authenticator;

const getAccessTokenFromUrl = (url: string): string => {
  const params = url.split('#')[1];
  const param = params.split('&')[0];
  return param.split('=')[1];
};
