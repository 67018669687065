import { Nota } from '../data/types/Nota';
import { Ocorrencia } from '../data/types/Ocorrencia';
import { getRequest } from './service';

export const getNotas = async (): Promise<Nota[]> => {
  return await getRequest<Nota[]>('/nota');
};

export const getNotasOcorrencia = async (
  idNota: number
): Promise<Ocorrencia[]> => {
  return await getRequest<Ocorrencia[]>(`/nota/${idNota}/ocorrencia`);
};

export const getNotaById = async (idNota: number): Promise<Nota> => {
  return await getRequest<Nota>(`/nota/${idNota}`);
};

export const getNotaByOcorrencia = async (
  codigoOcorrencia: number
): Promise<Nota[]> => {
  return await getRequest<Nota[]>(`/nota?codigoOcorrencia=${codigoOcorrencia}`);
};
