import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { Transition } from '@headlessui/react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { AppDispatch } from '../../../../state/store';
import { deleteUnidade, openFormUpdate } from './state/unidadeAction';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

export const columns = (dispatch: AppDispatch) =>
  [
    {
      field: 'idUnidade',
      headerName: 'ID',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.id,
    },
    { field: 'nome', headerName: 'Unidade', flex: 1 },
    { field: 'alias', headerName: 'Alias', flex: 5 },
    {
      field: 'prazoEntrega',
      headerName: 'Prazo de Entrega',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.prazoEntrega ? params.row.prazoEntrega + ' dias' : '';
      },
    },
    {
      field: 'tipoUnidade',
      headerName: 'Tipo de Unidade',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.tipoUnidade.tipo,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 100,
      renderCell: (param: any) => {
        const [showDelete, setShowDelete] = useState(false);
        const [showDeleteIcon, setShowDeleteIcon] = useState(true);
        const [showDeleteButton, setShowDeleteButton] = useState(false);

        const handleDeleteClick = () => {
          setShowDelete(true);
          setTimeout(() => {
            setShowDelete(false);
          }, 5000); // 5 seconds
        };

        return (
          <div className='text-[#3B82F6] flex '>
            <Transition
              show={!showDelete && showDeleteIcon}
              enter='transition-opacity duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              afterLeave={() => {
                setShowDeleteButton(true);
                setShowDeleteIcon(false);
              }}
            >
              <button
                onClick={() =>
                  dispatch(
                    openFormUpdate({ unidade: param.row, component: 'update' })
                  )
                }
              >
                <EditIcon />
              </button>
              <button onClick={handleDeleteClick}>
                <DeleteIcon />
              </button>
            </Transition>

            <Transition
              show={showDelete && showDeleteButton}
              enter='transition-opacity duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              afterLeave={() => {
                setShowDeleteButton(false);
                setShowDeleteIcon(true);
              }}
            >
              <div className='text-red-700'>
                <button
                  onClick={() => {
                    setShowDelete(true);
                    dispatch(deleteUnidade(param.row.idUnidade));
                  }}
                >
                  <HighlightOffIcon /> Deletar
                </button>
              </div>
            </Transition>
          </div>
        );
      },
    },
  ] as GridColDef[];
