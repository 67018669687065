import { Unidade } from '../data/types/Unidade';
import { deleteRequest, getRequest, postRequest } from './service';

export const getUnidades = async (): Promise<Unidade[]> => {
  return await getRequest<Unidade[]>('/unidade');
};

export const createUnidade = async (unidade: Unidade): Promise<void> => {
  await postRequest<Unidade>('/unidade', unidade);
};

export const updateUnidade = async (unidade: Unidade): Promise<void> => {
  await postRequest<Unidade>(`/unidade/${unidade.idUnidade}/`, unidade);
};

export const deleteUnidade = async (idUnidade: Number): Promise<void> => {
  await deleteRequest(`/unidade/${idUnidade}`);
};
