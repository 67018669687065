import { VencimentoNota } from '../data/enums/VencimentoNota';
import { Nota } from '../data/types/Nota';
import {
  calculateFrete,
  calculatePesoCubado,
  calculatePesoReal,
} from './calculate';
import { formatCurrency, formatPeso, formatPesoTon } from './formatter';

export const getPesoCubado = (notas: Nota[], digits: number): string => {
  return formatPeso(calculatePesoCubado(notas), digits);
};

export const getPesoReal = (notas: Nota[], digits: number): string => {
  return formatPeso(calculatePesoReal(notas), digits);
};

export const getPesoRealTon = (notas: Nota[]): string => {
  return formatPesoTon(calculatePesoReal(notas));
};

export const getPesoCubadoTon = (notas: Nota[]): string => {
  return formatPesoTon(calculatePesoCubado(notas));
};

export const getFrete = (notas: Nota[], digits: number): string => {
  return formatCurrency(calculateFrete(notas), digits);
};

export const getNotasVencidas = (notas: Nota[]): Nota[] => {
  return notas.filter(
    (nota) =>
      nota.vencimento === VencimentoNota.VENCIDA &&
      ![93, 94, 99].includes(nota.codigoOcorrencia)
  );
};
