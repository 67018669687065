import { createSlice } from '@reduxjs/toolkit';
import { Cidade } from '../../../../../data/types/Cidade';
import { Setor } from '../../../../../data/types/Setor';
import { Rota } from '../../../../../data/types/Rota';

export interface CidadeState {
  loading: boolean;
  cidades: Cidade[];
  updateForm: {
    isOpen: boolean;
    cidade: Cidade;
    isSaving: boolean;
  };
}

const initialState = {
  loading: false,
  cidades: [],
  updateForm: {
    isOpen: false,
    cidade: {} as Cidade,
    isSaving: false,
  },
} as CidadeState;

const cidadeSlice = createSlice({
  name: 'cidade',
  initialState,
  reducers: {
    openFormUpdateCidade: (state, action) => {
      state.updateForm.cidade = action.payload;
      state.updateForm.isOpen = true;
    },
    closeFormUpdateCidade: (state) => {
      if (!state.updateForm.isSaving) {
        state.updateForm = initialState.updateForm;
      }
    },
    saving: (state) => {
      state.updateForm.isSaving = true;
    },

    saved: (state) => {
      state.updateForm.isSaving = false;
    },
  },
});
export default cidadeSlice.reducer;
