import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { Nota } from '../../data/types/Nota';
import NotaFiscal from '../../components/NotaFiscal';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { openNotaTab } from '../nota/state/notaAction';
import { formatPeso } from '../../utils/formatter';
import { getPeso } from '../../utils/calculate';
import { fetchNotasAgendadas } from './state/agendadasFetch';
import * as AgendadasAction from './state/agendadasAction';
import { Box, Tab, Tabs, Tooltip } from '@mui/material';
import { FaTruck } from 'react-icons/fa';

const getNotas = (): Nota[] => {
  const notas = useSelector((state: RootState) => state.agendadas.data.notas);
  return notas;
};

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = ({ value, index, children }: TabPanelProps) => {
  return (
    <div hidden={value !== index} className='h-full'>
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
};

// se for 34, considera como entregue
// se a nota estiver aberta e a data já passou, bg red
const EventoPersonalizado = ({ eventInfo }: any) => {
  const nota = eventInfo.event.extendedProps.nota;
  var cor = '';
  if (nota.codigoOcorrencia === 34 || nota.status === 'finalizada') {
    cor = 'bg-[#46C928]';
  } else {
    // se a dataAgendamento for hoje e codigo ocorrencia 85
    if (nota.status === 'aberta' && nota.codigoOcorrencia === 85) {
      cor = 'bg-[#FFEC8B] text-black';
    } else {
      if (nota.dataAgendamento && new Date(nota.dataAgendamento) < new Date()) {
        cor = 'bg-[#FF0100]';
      }
    }
  }
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => dispatch(openNotaTab(eventInfo.event.extendedProps.nota))}
      className={`rounded-md border-none whitespace-normal ${
        cor === '' ? eventInfo.event.extendedProps.corDestinatario : cor
      } p-2 cursor-pointer`}
    >
      <div className='font-bold'>
        {eventInfo.event.title} ({nota.cidade})
      </div>
      <div>{eventInfo.event.extendedProps.nota.remetente}</div>
      <div>
        {formatPeso(getPeso(eventInfo.event.extendedProps.nota), 2)} /{' '}
        {eventInfo.event.extendedProps.nota.totalVolumes} Vol
      </div>
      <div>
        <div className='flex gap-1 items-center'>
          <FaMagnifyingGlass />
          <NotaFiscal
            numeroNotaFiscal={
              eventInfo.event.extendedProps.nota.numeroNotaFiscal
            }
          />
          {nota.idCarregamento && (
            <Tooltip
              title={`Nota incluída no carregamento #${nota.idCarregamento}`}
            >
              <FaTruck />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

const renderEventoPersonalizado = (eventInfo: any) => {
  return <EventoPersonalizado eventInfo={eventInfo} />;
};

const Agendadas = () => {
  const dispatch: AppDispatch = useDispatch();
  const [inicio, setInicio] = useState('');
  const [fim, setFim] = useState('');

  const openTab = (index: number) => {
    setValue(index);
    dispatch(AgendadasAction.openTab(index));
  };

  const loading = useSelector((state: RootState) => state.agendadas.loading);

  const tabIndex = useSelector((state: RootState) => state.agendadas.openTab);
  const [value, setValue] = React.useState(tabIndex);
  React.useEffect(() => {
    if (tabIndex !== undefined) {
      setValue(tabIndex);
    }
  }, [tabIndex]);

  useEffect(() => {
    if (inicio !== '' && fim !== '') {
      dispatch(fetchNotasAgendadas({ inicio, fim }));
    }
  }, [inicio, fim]);

  var notas = getNotas();

  const unidadeArray = [
    'TODAS',
    ...useSelector((state: RootState) => state.parameter.data.unidades)
      .map((unidade) => unidade.nome)
      .sort(),
  ];

  const cor1 = 'bg-primary-500';
  const cor2 = 'bg-primary-400';
  let corDestinatario = cor1;
  let destinatario = '';
  let unidadeEvento = '';
  let dataAgendamento = '';

  return (
    <div className='h-full w-full bg-white overflow-auto'>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: 'white',
        }}
      >
        <Tabs
          variant='scrollable'
          scrollButtons
          value={value}
          onChange={(event, newValue) => openTab(newValue)}
        >
          {unidadeArray.map((unidade, index) => (
            <Tab iconPosition='end' key={index + 1} label={unidade} />
          ))}
        </Tabs>
      </Box>
      <div className='p-10'>
        {unidadeArray.map((unidade, index) => {
          var notasUnidade;
          if (unidade !== 'TODAS') {
            notasUnidade = notas.filter((nota) => nota.unidade === unidade);
          } else {
            notasUnidade = [...notas];
          }

          const eventos = notasUnidade.map((nota) => {
            const dateOnly = nota.dataAgendamento?.split('T')[0];
            if (
              nota.destinatario !== destinatario ||
              unidadeEvento !== nota.unidade
            ) {
              corDestinatario = corDestinatario === cor1 ? cor2 : cor1;
              destinatario = nota.destinatario;
              unidadeEvento = nota.unidade;
            }

            return {
              title: `(${nota.unidade}) ${nota.destinatario}`,
              start: dateOnly,
              allDay: true,
              backgroundColor: '#fff',
              textColor: '#fff',
              extendedProps: {
                nota,
                corDestinatario,
              },
            };
          });
          return (
            <TabPanel key={index} value={value} index={index}>
              <FullCalendar
                eventContent={renderEventoPersonalizado}
                footerToolbar={false}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView='timeGridWeek'
                events={eventos}
                locale={ptBrLocale}
                allDaySlot={true}
                headerToolbar={{
                  left: `prev,next today`,
                  center: 'title',
                  right: `timeGridWeek,timeGridDay`,
                }}
                datesSet={(info) => {
                  setInicio(info.startStr.split('T')[0]);
                  setFim(info.endStr.split('T')[0]);
                }}
              />
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
};

export default Agendadas;
