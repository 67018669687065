import { postRequest } from './service';

export const createFeedback = async (
  nota: number,
  texto: string
): Promise<void> => {
  return await postRequest<any>('/feedback', {
    nota,
    texto,
  });
};
