import { Transition } from '@headlessui/react';
import { Button, Modal, Rating, TextField } from '@mui/material';
import React from 'react';
import { FaMessage } from 'react-icons/fa6';
import Fechar from './Fechar';
import { createFeedback } from '../state/application/applicationAction';
import { AppDispatch } from '../state/store';
import { useDispatch } from 'react-redux';

type FeedbackProps = {
  size: string;
};

const Feedback = ({ size }: FeedbackProps) => {
  const dispatch: AppDispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [rating, setRating] = React.useState<number | null>(0);
  const [feedback, setFeedback] = React.useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEnviar = () => {
    dispatch(createFeedback({ nota: rating ? rating : 0, texto: feedback }));
    handleClose();
  };

  React.useEffect(() => {
    setFeedback('');
    setRating(0);
  }, [open]);

  return (
    <div>
      <Transition
        show={size === '250'}
        enter='transition duration-[200ms] delay-200'
        enterFrom='opacity-0'
        enterTo='opacity-100'
      >
        <div
          onClick={handleOpen}
          className={`bg-gradient-to-r from-[#1565c0] to-[#2272ce] absolute bottom-0 w-[250px] text-white flex items-center justify-center gap-2 p-2 h-10 cursor-pointer`}
        >
          <FaMessage />
          {size === '250' ? 'Feedback' : ''}
        </div>
      </Transition>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          className='w-[700px]'
          sx={{ outline: 'none' }}
          disableAutoFocus={true}
        >
          <div className='bg-white absolute bottom-5 left-5'>
            <div className='bg-primary-600 h-[60px] p-5 flex items-center justify-between'>
              <Fechar dark={true} titulo='FEEDBACK' onClick={handleClose} />
            </div>
            <div className='p-5 flex flex-col gap-5'>
              <p className='mb-4'>
                Sua opinião é muito importante para nós! Deixe aqui sua
                sugestão, elogio ou reclamação. Com seu feedback, podemos
                melhorar nossos serviços e garantir uma experiência ainda melhor
                para você.
              </p>
              <TextField
                onChange={(e) => setFeedback(e.target.value)}
                label='Escreva seu feedback'
                fullWidth
                multiline
                rows={5}
                maxRows={5}
                helperText={`${feedback.length} / 500`}
                inputProps={{ maxLength: 500 }}
                sx={{ mb: 4 }}
              />
              <div className='flex items-center justify-end gap-5'>
                <div>Que nota você nos daria?</div>
                <Rating
                  onChange={(event, newValue) => setRating(newValue)}
                  name='rating-feedback'
                  value={rating}
                />

                <Button variant='contained' onClick={handleEnviar}>
                  Enviar
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Feedback;
