import { ReactNode } from 'react';

type BoxProps = {
  children: ReactNode;
  className?: string;
};

const Box = ({ children, className }: BoxProps) => {
  return (
    <div
      className={`bg-white border-solid border-[1px] border-gray-200 rounded-md w-full h-full overflow-hidden ${className}`}
    >
      {children}
    </div>
  );
};

export default Box;
