import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { Nota } from '../../data/types/Nota';
import { useDispatch } from 'react-redux';
import { columns } from './columns';
import { useEffect } from 'react';
import { fetchCanhotoData } from './state/canhotoFetch';
import { changeUnidadeFilter } from './state/canhotosAction';
import Loading from '../../components/Loading';
import GridUnidadeFiltro from '../../components/GridUnidadeFiltro';
import Page from '../../components/Page';

const getNotas = (): Nota[] => {
  return useSelector((state: RootState) => state.canhotos.data.notas);
};

const getUnidades = (notas: Nota[]): string[] => {
  const unidades = notas.map((nota) => nota.unidade);
  return Array.from(new Set(unidades));
};

const Canhotos = () => {
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCanhotoData());
  }, []);

  const notas = getNotas();

  const unidades = getUnidades(notas);

  const unidadesFiltro = useSelector(
    (state: RootState) => state.canhotos.unidadesFilter
  );

  if (notas.length === 0) {
    return <Loading />;
  }

  return (
    <Page title={`Canhotos Retidos (${notas.length})`}>
      <GridUnidadeFiltro
        notas={notas}
        unidades={unidades}
        unidadesFiltro={unidadesFiltro}
        changeUnidadeFilter={(unidades: string[]) =>
          dispatch(changeUnidadeFilter(unidades))
        }
        columns={columns}
        gridName='Canhotos Retidos'
      />
    </Page>
  );
};
export default Canhotos;
