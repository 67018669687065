import * as React from 'react';
import { Rota } from '../../data/types/Rota';
import './BuildCarregamento.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { BiArrowBack } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import {
  clearCarregamento,
  closeCarregamento,
  gerarCarregamento,
} from './buildCarregamentoAction';
import Select from './components/Select';
import Sort from './components/Sort';
import Totals from './components/Totals';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Nota } from '../../data/types/Nota';
import { AppDispatch } from '../../state/store';
import { TextField } from '@mui/material';
import { getCurrentDateTimeInBRTFormat } from '../../utils/date';

const Carregamento = () => {
  const navigate = useNavigate();
  const appDispatch: AppDispatch = useDispatch();
  const dispatch = useDispatch();
  const [observacao, setObservacao] = React.useState('');
  const rota = useSelector((state: RootState) => state.buildCarregamento.rota);
  const unidade = useSelector(
    (state: RootState) => state.buildCarregamento.unidade
  );
  const ordemCarregamento = useSelector(
    (state: RootState) => state.buildCarregamento.ordemCarregamento
  );
  const carregamento = useSelector(
    (state: RootState) => state.buildCarregamento.notas
  );

  var notas = useSelector((state: RootState) => state.carregamento.data.notas)
    .filter(
      (nota) => nota.rota?.idRota === rota.idRota && nota.unidade === unidade
    )
    .sort((a: Nota, b: Nota) => {
      if (a.destinatario === b.destinatario) {
        return a.cnpjDestinatario > b.cnpjDestinatario ? 1 : -1;
      }
      return a.destinatario > b.destinatario ? 1 : -1;
    });

  if (Object.keys(rota).length === 0) {
    navigate('/');
    return null;
  }

  const closeCarregamentoHandler = () => {
    dispatch(closeCarregamento());
    window.history.back();
  };

  const gerarCarregamentoHandler = () => {
    var fichaCarregamentoList = [] as Nota[];

    ordemCarregamento.forEach((nomeCidade) => {
      const notas = carregamento.filter(
        (nota: Nota) => nota.cidade === nomeCidade
      );
      fichaCarregamentoList = fichaCarregamentoList.concat(notas);
    });

    const fichaCarregamento = fichaCarregamentoList.map((nota, index) => {
      return { idNota: nota.idNota, ordemCarregamento: index };
    });
    appDispatch(
      gerarCarregamento({
        notas: fichaCarregamento,
        observacao,
        idRota: rota.idRota,
        data: getCurrentDateTimeInBRTFormat(),
      })
    );
  };

  return (
    <div className={'carregamento w-full h-full'}>
      <div
        className={
          'carregamento__header font-bold text-lg flex text-white bg-primary-500 items-center'
        }
      >
        <span
          onClick={() => closeCarregamentoHandler()}
          className='pl-5 cursor-pointer'
        >
          <BiArrowBack />
        </span>
        <span className='pl-5 flex items-center w-full'>
          {unidade.toUpperCase()} - {rota.nome.toUpperCase()}
        </span>
      </div>
      <div className={'carregamento__body'}>
        <div className={'carregamento__body-select'}>
          <Select notas={notas} />
        </div>
        <div
          className={
            'carregamento__body-sort bg-primary-200 grid grid-rows-[70%_auto] overflow-hidden'
          }
        >
          <Sort ordemCarregamento={ordemCarregamento} />
          <div className='h-full w-full overflow-hidden grid grid-rows-[50px_auto]'>
            <div className='p-5 text-gray-600'></div>
            <TextField
              onChange={(e) => setObservacao(e.target.value)}
              label='Observação'
              sx={{
                padding: '10px',
              }}
              fullWidth
              multiline
              maxRows={5}
              helperText={`${
                observacao !== null ? observacao.length : 0
              } / 500`}
              rows={5}
              InputProps={{
                sx: {
                  height: '100%',
                  backgroundColor: '#fff',
                },
              }}
              inputProps={{ maxLength: 500 }}
            />
          </div>
        </div>
        <div
          className={
            'carregamento__body-footer bg-primary-500 flex items-center justify-center'
          }
        >
          <Totals />
        </div>
      </div>
      <div className={'carregamento__footer flex'}>
        <div className='items-center h-[80px] w-full flex text-xs space-x-4 pl-10'>
          <div className='w-4 h-4 bg-agendado rounded-[50%]'></div>
          <span>Agendado</span>
          <div className='w-4 h-4 bg-vencido rounded-[50%]'></div>
          <span>Vencido</span>
          <div className='w-4 h-4 bg-hoje rounded-[50%]'></div>
          <span>Vencendo Hoje</span>
          <div className='w-4 h-4 bg-proximo rounded-[50%]'></div>
          <span>Vencendo Próximo</span>
          <div className='m-3 flex items-center justify-center text-bold w-5 text-xs rounded-xl bg-vencido text-white pl-1 pr-1'>
            P
          </div>
          <span>Pendência</span>
          <div className='m-3 flex items-center justify-center text-bold w-5 text-xs rounded-xl bg-vencido text-white pl-1 pr-1'>
            D
          </div>
          <span>Devolução</span>
          <div className='m-3 flex items-center justify-center text-bold w-5 text-xs rounded-xl bg-vencido text-white pl-1 pr-1'>
            R
          </div>
          <span>Coleta Reversa</span>
        </div>
        <div className='h-[80px] w-full flex items-center'>
          <div className='mr-0 ml-auto pr-10'>
            <Button
              onClick={() => dispatch(clearCarregamento())}
              disabled={carregamento.length === 0}
              variant='outlined'
            >
              Limpar
            </Button>
            <Button
              onClick={() => gerarCarregamentoHandler()}
              disabled={carregamento.length === 0}
              variant='contained'
            >
              Gerar Carregamento
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Carregamento;
