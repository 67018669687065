import { createSlice, current } from '@reduxjs/toolkit';
import { Nota } from '../../../data/types/Nota';
import { fetchCanhotoData } from './canhotoFetch';

export interface CanhotosState {
  unidadesFilter: string[];
  data: {
    notas: Nota[];
  };
}
const initialState = {
  unidadesFilter: [],
  data: {
    notas: [],
  },
} as CanhotosState;

const canhotosSlice = createSlice({
  name: 'canhotos',
  initialState,
  reducers: {
    changeUnidadeFilter: (state, action) => {
      state.unidadesFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCanhotoData.pending.type, (state) => {});
    builder.addCase(fetchCanhotoData.fulfilled, (state, action) => {
      state.data.notas = action.payload;
    });
  },
});
export default canhotosSlice.reducer;
