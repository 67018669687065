type CardProps = {
  title: string;
  children: React.ReactNode;
  icon: React.ReactNode;
};

const Card = (props: CardProps) => {
  const children = props.children;
  const title = props.title;
  const icon = props.icon;
  return (
    <div className='shadow-sm col-span-12 md:col-span-6 xl:col-span-3 py-10 px-5 bg-white shaddow-md'>
      <div className='flex flex-row'>
        <div className='w-full'>
          <div>{title}</div>
          <div className='text-lg font-extrabold w-full'>{children}</div>
        </div>
        <div className='ml-auto items-center'>
          <div className='rounded-full bg-primary-200 text-md p-2'>{icon}</div>
        </div>
      </div>
    </div>
  );
};
export default Card;
