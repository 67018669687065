import { Nota } from '../data/types/Nota';
import Box from './Box';
import ExportToExcel from './ExportToExcel';

type FiltroOcorrenciaProps = {
  unidades: string[];
  unidadesFiltro: string[];
  filterCallback: (unidades: string[]) => void;
  notas: Nota[];
  nome?: string;
  columns?: string[];
};

const FiltroOcorrencia = (props: FiltroOcorrenciaProps) => {
  const unidades = props.unidades.sort();
  const unidadesFiltro = props.unidadesFiltro;
  const filterCallback = props.filterCallback;
  const notas = props.notas;
  const nome = props.nome;

  const unselectedStyle =
    'bg-gray-200 text-gray-600 p-2 rounded-xl cursor-pointer shadow-sm px-2 whitespace-nowrap inline-block';

  const selectedStyle =
    'bg-[#2965C0] text-white p-2 rounded-xl cursor-pointer shadow-sm px-2 whitespace-nowrap inline-block';

  return (
    <Box>
      <div className='text-xs bg-white p-5 overflow-auto w-full break-words'>
        <div className='flex flex-row items-center overflow-auto'>
          <div>Filtro:</div>
          <div className='flex flex-row space-x-2 pl-5 overflow-auto max-h-60 pb-5'>
            {unidades
              .filter(
                (unidade) =>
                  notas.filter((nota) => nota.unidade === unidade).length > 0
              )
              .map((unidade) => (
                <div
                  key={unidade}
                  onClick={() => {
                    if (unidadesFiltro.includes(unidade)) {
                      filterCallback(
                        unidadesFiltro.filter((u) => u !== unidade)
                      );
                    } else {
                      filterCallback([...unidadesFiltro, unidade]);
                    }
                  }}
                  className={
                    unidadesFiltro.includes(unidade)
                      ? selectedStyle
                      : unselectedStyle
                  }
                >
                  {unidade}{' '}
                  <span className='hidden md:inline-block'>
                    ({notas.filter((nota) => nota.unidade === unidade).length})
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default FiltroOcorrencia;
