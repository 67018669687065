import { createSlice } from '@reduxjs/toolkit';
import { Cidade } from '../../../data/types/Cidade';
import { Cliente } from '../../../data/types/Cliente';
import { Rota } from '../../../data/types/Rota';
import { fetchParameterData } from './parameterFetch';
import { Usuario } from '../../../data/types/Usuario';
import { Parametro } from '../../../data/types/Parametro';
import { Setor } from '../../../data/types/Setor';
import { Unidade } from '../../../data/types/Unidade';

export interface ParameterState {
  status: 'loading' | 'failed' | 'success';
  data: {
    cidades: Cidade[];
    clientes: Cliente[];
    rotas: Rota[];
    parametros: Parametro[];
    usuarios: Usuario[];
    setores: Setor[];
    unidades: Unidade[];
  };
  tab: number;
}

const initialState = {
  status: 'loading',
  data: {
    cidades: [],
    clientes: [],
    rotas: [],
    parametros: [],
    usuarios: [],
    setores: [],
    unidades: [],
  },
  tab: 0,
} as ParameterState;

const parameterSlice = createSlice({
  name: 'parameter',
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchParameterData.pending.type, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchParameterData.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = action.payload;
    });
  },
});
export default parameterSlice.reducer;
