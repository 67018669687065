import { createAction } from '@reduxjs/toolkit';
import { Nota } from '../../data/types/Nota';
import { createAsyncThunk } from '@reduxjs/toolkit';
import DownloadFichaCarregamento from './components/DownloadFichaCarregamento';
import { openSnackbar } from '../../state/application/applicationAction';

import { postCarregamento } from '../../services/CarregamentoService';
import { RootState } from '../../state/store';
import { Rota } from '../../data/types/Rota';

export const openCarretamento = createAction<{ unidade: string; rota: Rota }>(
  'carregamento/openCarretamento'
);
export const closeCarregamento = createAction('carregamento/closeCarretamento');
export const selectNota = createAction<{
  select: boolean;
  nota: Nota;
}>('carregamento/selectNota');
export const selectAll = createAction<{
  select: boolean;
  cidade: string;
  notas: Nota[];
}>('carregamento/selectAll');
export const changeOrdemCarregamento = createAction<{
  index: number;
  destination: number | undefined;
}>('carregamento/changeOrdemCarregamento');
export const clearCarregamento = createAction('carregamento/clearCarregamento');

export const gerarCarregamento = createAsyncThunk(
  'carregamento/gerarCarregamento',
  async (carregamento: any, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const numeroCarregamento = await postCarregamento(carregamento);
    thunkApi.dispatch(
      openSnackbar({
        message: 'Carregamento ' + numeroCarregamento + ' gerado com sucesso',
        severity: 'success',
      })
    );
    DownloadFichaCarregamento(
      numeroCarregamento,
      state.carregamento.data.notas,
      state.parameter.data.rotas.find(
        (rota) => rota.idRota === carregamento.idRota
      ),
      carregamento.observacao,
      carregamento.data
    );
    thunkApi.dispatch(closeCarregamento());
    return {};
  }
);
