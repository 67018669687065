import { createSlice, current } from '@reduxjs/toolkit';
import { fetchAtrasadasData } from './atrasadasFetch';
import { Nota } from '../../../data/types/Nota';

export interface AtrasadasState {
  unidadesFilter: string[];
  isLoading: boolean;
  data: {
    notas: Nota[];
    unidades: string[];
  };
}
const initialState = {
  unidadesFilter: [],
  isLoading: false,
  data: {
    notas: [],
    unidades: [],
  },
} as AtrasadasState;

const atrasadasSlice = createSlice({
  name: 'atrasadas',
  initialState,
  reducers: {
    changeUnidadeFilter: (state, action) => {
      state.unidadesFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAtrasadasData.pending.type, (state) => {
      if (state.data.notas.length === 0) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchAtrasadasData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.notas = action.payload;
    });
  },
});
export default atrasadasSlice.reducer;
