var meses = new Array(
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
);

export const hoje = () => {
  return new Date();
};

export const mesAtual = () => {
  return new Date().getMonth();
};

export const anoAtual = () => {
  return new Date().getFullYear;
};

//retorna Data no formato '2023-08-14'
export const hojeISOString = (dias?: number) => {
  if (dias == null) {
    return new Date().toISOString().substring(0, 10);
  } else {
    return new Date(new Date().getTime() - dias * 24 * 60 * 60 * 1000)
      .toISOString()
      .substring(0, 10);
  }
};

//retorna Data no formato '2023-08-14'
export const inicioMesISOString = (mes?: number) => {
  const date = new Date();

  if (mes != null) {
    date.setMonth(mes);
  }

  date.setDate(1);

  return date.toISOString().substring(0, 10);
};

//retorna Data no formato '2023-08-14'
export const fimMesISOString = (mes?: number) => {
  const date = new Date();

  if (mes != null) {
    date.setMonth(mes);
  }

  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return lastDay.toISOString().substring(0, 10);
};

//retorna Data no formato '2023-08-14'
export const criarDataISOString = (
  dia?: number,
  mes?: number,
  ano?: number
) => {
  const date = new Date();

  if (dia != null) {
    date.setDate(dia);
  }

  if (mes != null) {
    date.setMonth(mes);
  }

  if (ano != null) {
    date.setFullYear(ano);
  }

  return date.toISOString().substring(0, 10);
};

//retorna Data no formato '2023-08-14'
export const getMesPorExtenso = (mes: number) => {
  return meses[mes];
};

export const getCurrentDateTimeInBRTFormat = () => {
  // Obtém a data e hora atuais no fuso horário local do usuário
  let now = new Date();

  // Define o fuso horário para BRT (Brasília)
  now.setUTCHours(now.getUTCHours() - 3);

  // Formata a data para o formato desejado (YYYY-MM-DDTHH:MM:SS)
  let year = now.getUTCFullYear();
  let month = String(now.getUTCMonth() + 1).padStart(2, '0');
  let day = String(now.getUTCDate()).padStart(2, '0');
  let hours = String(now.getUTCHours()).padStart(2, '0');
  let minutes = String(now.getUTCMinutes()).padStart(2, '0');
  let seconds = String(now.getUTCSeconds()).padStart(2, '0');

  // Monta a data no formato especificado
  let formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  return formattedDateTime;
};

export const getCurrentDateInBRTFormat = () => {
  // Obtém a data e hora atuais no fuso horário local do usuário
  let now = new Date();

  // Define o fuso horário para BRT (Brasília)
  now.setUTCHours(now.getUTCHours() - 3);

  // Formata a data para o formato desejado (YYYY-MM-DDTHH:MM:SS)
  let year = now.getUTCFullYear();
  let month = String(now.getUTCMonth() + 1).padStart(2, '0');
  let day = String(now.getUTCDate()).padStart(2, '0');

  // Monta a data no formato especificado
  let formattedDateTime = `${year}-${month}-${day}`;

  return formattedDateTime;
};

export const getCurrentDatePlusOneInBRTFormat = () => {
  // Obtém a data e hora atuais no fuso horário local do usuário
  let now = new Date();

  // Define o fuso horário para BRT (Brasília)
  now.setUTCHours(now.getUTCHours() - 3);

  // Adiciona um dia à data atual
  now.setDate(now.getDate() + 1);

  // Formata a data para o formato desejado (YYYY-MM-DD)
  let year = now.getUTCFullYear();
  let month = String(now.getUTCMonth() + 1).padStart(2, '0');
  let day = String(now.getUTCDate()).padStart(2, '0');

  // Monta a data no formato especificado
  let formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const maiorQueDataAtual = (d1: string) => {
  let date1 = new Date(d1).getTime();
  let hoje = new Date().getTime();
  if (date1 <= hoje) {
    return false;
  }

  return true;
};
