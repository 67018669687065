import { GridColDef, GridPagination } from '@mui/x-data-grid';
import ExportToExcel from './ExportToExcel';

const CustomPagination = ({ paginationProps, data, nome, columns }: any) => {
  return (
    <div className='flex items-center w-full'>
      <div className='px-5 ml-0 flex'>
        <ExportToExcel
          data={data}
          fileName={nome ? nome : ''}
          columns={columns.map((column: GridColDef) => {
            return {
              header: column.headerName,
              field: column.field,
            };
          })}
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        <GridPagination {...paginationProps} />
      </div>
    </div>
  );
};
export default CustomPagination;
