import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { useDispatch } from 'react-redux';
import FiltroOcorrencia from '../../components/FiltroOcorrencia';
import { columns } from './columns';
import { Nota } from '../../data/types/Nota';
import { changeUnidadeFilter } from './state/agendamentoAction';
import { DataGrid, GridPagination, ptBR } from '@mui/x-data-grid';
import { fetchAgendamentoData } from './state/agendamentoFetch';
import React from 'react';
import Loading from '../../components/Loading';
import ExportToExcel from '../../components/ExportToExcel';
import CustomPagination from '../../components/CustomPagination';
import { getCurrentDateTimeInBRTFormat } from '../../utils/date';
import Page from '../../components/Page';
import GridUnidadeFiltro from '../../components/GridUnidadeFiltro';

type NotasByDestinatario = {
  destinatario: string;
  cnpjDestinatario: string;
  cidade: string;
  notas: Nota[];
};

const getNotas = (): Nota[] => {
  const notas = useSelector((state: RootState) => state.agendamento.data.notas);
  return notas.filter((nota) => nota.agendado);
};

const getUnidade = (notas: Nota[]): string[] => {
  const unidades = notas
    .filter((nota) => nota.rota !== null)
    .map((nota) => nota.rota?.unidade.nome) as string[];
  return Array.from(new Set(unidades));
};

const Agendaamento = () => {
  const dispatch: AppDispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchAgendamentoData());
  }, []);
  const notas = getNotas();
  const clientes = useSelector(
    (state: RootState) => state.parameter.data.clientes
  ).filter((cliente) => cliente.agendado);

  const notasByDestinatario = notas.reduce(
    (acc: NotasByDestinatario[], nota) => {
      const index = acc.findIndex(
        (n) => n.cnpjDestinatario === nota.cnpjDestinatario
      );
      if (index === -1) {
        acc.push({
          destinatario: nota.destinatario,
          cnpjDestinatario: nota.cnpjDestinatario,
          cidade: nota.cidade,
          notas: [nota],
        });
      } else {
        acc[index].notas.push(nota);
      }
      return acc;
    },
    []
  );

  const unidades = getUnidade(notas);
  const unidadesFiltro = useSelector(
    (state: RootState) => state.agendamento.unidadesFilter
  );

  if (notas.length === 0) {
    return <Loading />;
  }

  const filteredNotas = notas.filter((nota) => {
    if (unidadesFiltro !== undefined && unidades !== undefined) {
      if (unidadesFiltro.length === 0) {
        return unidades.includes(nota.unidade);
      } else {
        return unidadesFiltro.includes(nota.unidade);
      }
    }
    return nota;
  });

  return (
    <div className='p-5 w-full grid grid-rows-[60px_80px_auto] h-full overflow-hidden'>
      <div className='text-2xl'>Notas Agendadas ({notas.length})</div>
      <div>
        <FiltroOcorrencia
          notas={notas}
          filterCallback={(unidades: string[]) =>
            dispatch(changeUnidadeFilter(unidades))
          }
          unidades={unidades}
          unidadesFiltro={unidadesFiltro}
        />
      </div>
      <div className='overflow-hidden'>
        <div className='bg-white h-full overflow-hidden'>
          <DataGrid
            slots={{
              pagination: (props) =>
                CustomPagination({
                  props,
                  data: filteredNotas,
                  nome: `Agendamento ${getCurrentDateTimeInBRTFormat()}`,
                  columns: [
                    { field: 'destinatario', headerName: 'Destinatário' },
                    { field: 'cidade', headerName: 'Cidade' },
                    {
                      field: 'numeroNotaFiscal',
                      headerName: 'NF',
                    },
                    {
                      field: 'codigoOcorrencia',
                      headerName: 'Ocorrência',
                    },
                    { field: 'dataAutorizacao', headerName: 'Emissão' },
                    { field: 'remetente', headerName: 'Remetente' },
                  ],
                }),
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            getRowHeight={() => 'auto'}
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            initialState={{
              sorting: {
                sortModel: [{ field: 'nome', sort: 'asc' }],
              },
            }}
            rows={notasByDestinatario.filter((entrega) => {
              return (
                unidadesFiltro.length === 0 ||
                entrega.notas.some((nota) => {
                  return unidadesFiltro.includes(
                    nota.rota?.unidade.nome as string
                  );
                })
              );
            })}
            getRowId={(nota) => nota.cnpjDestinatario}
            columns={columns(dispatch, unidadesFiltro, unidades, clientes)}
            onCellKeyDown={(_, events) => events.stopPropagation()}
          />
        </div>
      </div>
    </div>
  );
};
export default Agendaamento;
