import { createSlice, current } from '@reduxjs/toolkit';
import { Nota } from '../../../data/types/Nota';
import { fetchNotasAgendadas } from './agendadasFetch';

export interface AgendamentoState {
  openTab: number;
  loading: boolean;
  data: {
    notas: Nota[];
  };
}
const initialState = {
  openTab: 0,
  loading: true,
  data: {
    notas: [] as Nota[],
  },
} as AgendamentoState;

const agendadasSlice = createSlice({
  name: 'agendadas',
  initialState,
  reducers: {
    openTab: (state, action) => {
      state.openTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotasAgendadas.pending.type, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNotasAgendadas.fulfilled, (state, action) => {
      state.data.notas = sortNotas(action.payload);
      state.loading = false;
    });
  },
});

// const sortNotas = (notas: Nota[]) => {
//   return notas.sort((a, b) => {
//     if (a.dataAgendamento === undefined && b.dataAgendamento === undefined)
//       return 0;
//     if (a.dataAgendamento === undefined) return 1;
//     if (b.dataAgendamento === undefined) return -1;

//     const dateA = new Date(a.dataAgendamento);
//     const dateB = new Date(b.dataAgendamento);

//     if (dateA < dateB) return -1;
//     if (dateA > dateB) return 1;

//     return a.destinatario.localeCompare(b.destinatario);
//   });
// };

const sortNotas = (notas: Nota[]) => {
  return notas.sort((a, b) => {
    // Primeiro, ordena por unidade
    const unidadeCompare = a.unidade.localeCompare(b.unidade);
    if (unidadeCompare !== 0) {
      return unidadeCompare;
    }

    // Se as unidades forem iguais, ordena por dataAgendamento
    if (a.dataAgendamento === undefined && b.dataAgendamento === undefined)
      return 0;
    if (a.dataAgendamento === undefined) return 1;
    if (b.dataAgendamento === undefined) return -1;

    const dateA = new Date(a.dataAgendamento);
    const dateB = new Date(b.dataAgendamento);

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    // Se a unidade e as datas forem iguais, ordena por destinatário
    return a.destinatario.localeCompare(b.destinatario);
  });
};

export default agendadasSlice.reducer;
