import { Nota } from '../../../data/types/Nota';
import { VencimentoNota } from '../../../data/enums/VencimentoNota';

type StatusNotasProps = {
  notas: Nota[];
};

const StatusNotas = (props: StatusNotasProps) => {
  const notas = props.notas;

  const qtdnotaVencido = notas.filter(
    (nota) => nota.vencimento === VencimentoNota.VENCIDA
  ).length;
  const qtdnotaVencendoHoje = notas.filter(
    (nota) => nota.vencimento === VencimentoNota.HOJE
  ).length;
  const qtdnotaVencendoProximo = notas.filter(
    (nota) => nota.vencimento === VencimentoNota.A_VENCER
  ).length;

  const qtdnotaAgendado = notas.filter((nota) => nota.agendado).length;
  return (
    <div className='flex space-x-2 p-2'>
      {qtdnotaAgendado > 0 && (
        <div className=' flex items-center justify-center text-white bg-agendado rounded-lg pl-2 pr-2'>
          {qtdnotaAgendado}
        </div>
      )}
      {qtdnotaVencido > 0 && (
        <div className=' flex items-center justify-center text-white bg-vencido rounded-lg pl-2 pr-2'>
          {qtdnotaVencido}
        </div>
      )}
      {qtdnotaVencendoHoje > 0 && (
        <div className=' flex items-center justify-center bg-hoje text-black rounded-lg pl-2 pr-2'>
          {qtdnotaVencendoHoje}
        </div>
      )}
      {qtdnotaVencendoProximo > 0 && (
        <div className=' flex items-center justify-center bg-proximo text-black rounded-lg pl-2 pr-2'>
          {qtdnotaVencendoProximo}
        </div>
      )}
    </div>
  );
};
export default StatusNotas;
