import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNotaById, getNotasOcorrencia } from '../../../services/NotaService';
import { getAcompanhamento } from '../../../services/AcompanhamentoService';

export const fetchNotaData = createAsyncThunk(
  'nota/fetch',
  async (idNota: number) => {
    const nota = await getNotaById(idNota);
    const ocorrencias = await getNotasOcorrencia(idNota);
    const acompanhamentos = await getAcompanhamento(idNota);
    return {
      nota,
      ocorrencias,
      acompanhamentos,
    };
  }
);
