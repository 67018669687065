import { Cliente } from '../data/types/Cliente';
import { getRequest, postRequest } from './service';

export const getClientes = async (): Promise<Cliente[]> => {
  return await getRequest<Cliente[]>('/cliente');
};

export const updateCliente = async (cliente: Cliente) => {
  return await postRequest<Cliente>(`/cliente/${cliente.idCliente}`, cliente);
};
