import * as React from 'react';
import { CgProfile } from 'react-icons/cg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { ACCESS_TOKEN } from '../../../data/constants/accessToken';

const Profile = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSair = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    window.location.reload();
  };

  return (
    <div>
      <Button onClick={handleClick}>
        <div className='text-4xl'>
          <CgProfile />
        </div>
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleSair}>Sair</MenuItem>
      </Menu>
    </div>
  );
};
export default Profile;
