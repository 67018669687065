import axios, { AxiosResponse } from 'axios';
import { ACCESS_TOKEN } from '../../data/constants/accessToken';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../state/application/applicationAction';
import { store } from '../../state/store';

const apiClient = axios.create({
  baseURL: 'https://43iie64z43.execute-api.us-east-2.amazonaws.com/prod/',
  timeout: 60000, // TODO: Rever esse tempo de timeout
});

apiClient.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = localStorage.getItem(ACCESS_TOKEN);
    config.headers['trace-id'] = uuidv4();
    return config;
  },
  (error) => {
    // Faz algo com o erro da requisição
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const { dispatch } = store;

    return Promise.reject(error); // Continue a propagar o erro
  }
);

export default apiClient;
