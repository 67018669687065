import { Dispatch } from 'react';
import NotaFiscal from '../../components/NotaFiscal';
import StatusNota from '../../components/StatusNota';
import { formatCurrency, formatDate } from '../../utils/formatter';
import { AppDispatch } from '../../state/store';
import { openNotaTab } from '../nota/state/notaAction';
import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import NumeroNotaFiscal from '../../components/NumeroNotaFiscal';
import { Nota } from '../../data/types/Nota';

export const columns = [
  {
    field: 'numeroNotaFiscal',
    headerName: 'NF',
    flex: 0.7,
    renderCell: ({ row }: GridRenderCellParams<Nota>) => {
      return <NumeroNotaFiscal nota={row} />;
    },
  },
  { field: 'codigoOcorrencia', headerName: 'Ocorrência', flex: 0.5 },
  { field: 'descricaoUltimaOcorrencia', headerName: 'Descrição', flex: 2 },
  {
    field: 'dataAutorizacao',
    headerName: 'Emissão',
    flex: 0.7,
    renderCell: (params: any) => {
      return <div>{formatDate(params.row.dataAutorizacao)}</div>;
    },
  },
  { field: 'remetente', headerName: 'Remetente', flex: 2 },
  { field: 'destinatario', headerName: 'Destinatário', flex: 2 },
  { field: 'cidade', headerName: 'Cidade', flex: 1 },

  {
    field: 'unidade',
    headerName: 'Unidade',
    flex: 0.7,

    renderCell: (params: any) => {
      return <div>{params.row.unidade}</div>;
    },
  },
  {
    field: 'dataPrevisaoEntrega',
    headerName: 'Data Limite',
    flex: 0.7,
    renderCell: (params: any) => <StatusNota nota={params.row} />,
  },
];
