import { Usuario } from '../data/types/Usuario';
import { deleteRequest, getRequest, postRequest } from './service';

export const getUsuarios = async (): Promise<Usuario[]> => {
  return await getRequest<Usuario[]>('/usuario');
};

export const deleteUsuario = async (idUsuario: string) => {
  return await deleteRequest<Usuario>(`/usuario/${idUsuario}`);
};

export const createUsuario = async (email: string) => {
  return await postRequest<any>('/usuario', { email });
};
